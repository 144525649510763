import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
export const messages = defineMessages({
  noPreviewTitle: {
    id: 'noPreview.title',
    defaultMessage: 'No preview available',
  },
  notFoundTitle: {
    id: 'notFound.title',
    defaultMessage: 'Page not found!',
  },
  serverErrorTitle: {
    id: 'serverError.title',
    defaultMessage: 'Page Unavailable',
  },
  serverErrorSubtitle: {
    id: 'serverError.subtitle',
    defaultMessage: "Sorry, there were some technical issues while processing your request. <strong>See Cloudinary's service status.</strong>",
  },
  serverErrorButton: {
    id: 'serverError.button',
    defaultMessage: 'Contact Us',
  },
  wasDeletedTitle: {
    id: 'wasDeleted.title',
    defaultMessage: 'This shared file or folder link has been removed or is unavailable to you.',
  },

  // somethingWentWrong
  somethingWentWrong_title: {
    id: 'somethingWentWrong_title',
    defaultMessage: 'Hmmm. Looks like something went wrong...',
  },
  somethingWentWrong_subTitle: {
    id: 'somethingWentWrong_subTitle',
    defaultMessage:
      'It seems that an error has occurred. If this error doesn’t resolve on its own, feel free to submit a <strong>support request.</strong>',
  },
  somethingWentWrong_contentTitle: {
    id: 'somethingWentWrong_contentTitle',
    defaultMessage: 'In the meantime, you might want to try the following:',
  },
  somethingWentWrong_refreshThePage: {
    id: 'somethingWentWrong_refreshThePage',
    defaultMessage: '<strong>Refresh</strong> the page.',
  },
  somethingWentWrong_returnPreviousPage: {
    id: 'somethingWentWrong_returnPreviousPage',
    defaultMessage: 'Return to the previous page and try the operation again.',
  },
  somethingWentWrong_checkSystemStatus: {
    id: 'somethingWentWrong_checkSystemStatus',
    defaultMessage: 'Check the <strong>Cloudinary system status</strong> page and let us know what happened.',
  },
});
