import { Environment } from './Environment';
import { getHost } from './getHost';

/*
 * Retrieves environment based on host suffix.
 */
export const getEnvironmentFromHost = () => {
  const host = getHost();
  const firstSegment = host.split('.')[0];

  if (firstSegment.startsWith('localhost') || firstSegment.endsWith('dev') || firstSegment.startsWith('s3-proxy')) {
    return Environment.Development;
  }

  if (firstSegment.endsWith('staging')) {
    return Environment.Staging;
  }
  if (firstSegment.endsWith('nightly')) {
    return Environment.Nightly;
  }
  if (firstSegment.endsWith('staging2')) {
    return Environment.Staging2;
  }

  return Environment.Production;
};
