import { FC, PropsWithChildren } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { combineValues, createTheme, Mixin } from '@clds/component-theme';
import { checkerboard } from '@clds/style-utils';
import { checkerboardTheme } from './theme';

export const withCheckerboardBackground = <Props extends PropsWithChildren, C extends StyledComponent<FC<Props>, Props>>(WrappedComponent: C) => styled(
  WrappedComponent
)`
  ${combineValues(checkerboardTheme, ({ color1, color2, size }) => checkerboard(color1, color2, size))};
`;

export const withThemedCheckerboardBackground =
  (theme: { color1: string | Mixin<string>; color2: string | Mixin<string>; size: number }) =>
  <Props extends PropsWithChildren, C extends StyledComponent<FC<Props>, Props>>(WrappedComponent: C) => {
    const themeInstance = createTheme(theme);

    return styled(WrappedComponent)`
      ${combineValues(themeInstance, ({ color1, color2, size }) => checkerboard(color1, color2, size))};
    `;
  };

export default withCheckerboardBackground;
