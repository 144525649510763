import { useTitle } from 'react-use';
import { PromiseState } from '@cld/use-promise-state';

const LOADING_TITLE = '…';

const resolveTitleFromPromise = <T,>(dataPromiseState: PromiseState<T>, titleAccessor: (data: T) => string, titleOnError: string) => {
  const { isLoading, error, data } = dataPromiseState;

  if (isLoading) {
    return LOADING_TITLE;
  }

  if (error || !data) {
    return titleOnError;
  }

  return titleAccessor(data);
};

export const useTitleFromPromise = <T,>(dataPromiseState: PromiseState<T>, titleAccessor: (data: T) => string, titleOnError: string) => {
  const title = resolveTitleFromPromise(dataPromiseState, titleAccessor, titleOnError);
  useTitle(title);
};
