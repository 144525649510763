import 'whatwg-fetch';
import { isEmpty } from 'lodash';
import querystring from 'querystring';
import getEnvConfig from '@cld/env-config';
import { QueryFunctionContext } from '@tanstack/react-query';
import { getProperties } from '@cld/console-apps-services';
import { CollectionInfo, PortalData } from './PortalData';
import { parseCollections, parseInfo } from './parseData';
import { MAX_ASSET_COUNT_TO_LOAD } from '../../components/App.constants';

const buildRequestUrl = (customerId: string, portalId: string, type: 'info' | 'collections', extraQueryParameters = {}) => {
  const apiBaseUrl = getEnvConfig().apiBaseUrl;
  let queryParams = querystring.encode(extraQueryParameters);

  if (!isEmpty(queryParams)) {
    queryParams = `?${queryParams}`;
  }

  return `${apiBaseUrl}/console/api/v1/public/${customerId}/portals/${portalId}/${type}${queryParams}`;
};

const fetchData = async (type: 'info' | 'collections', extraQueryParameters = {}) => {
  const { customerId, resourceId: portalId } = getProperties();

  if (!(portalId && customerId)) {
    throw new Error('corrupted properties');
  }

  const requestUrl = buildRequestUrl(customerId, portalId, type, extraQueryParameters);
  const response = await fetch(requestUrl);

  if (response.status >= 500) {
    throw new Error('delivery failure');
  }

  if (response.status >= 400) {
    throw new Error('portal not found');
  }

  return response.json() as Promise<unknown>;
};

export const getCollections = async (collectionIds?: string[]) => {
  const data = await fetchData('collections', { 'collection_ids[]': collectionIds });
  return parseCollections(data);
};

export const getInfo = async () => {
  const data = await fetchData('info');
  return parseInfo(data);
};

export const sendCloudinaryAnalytics = async ({ eventName, ...eventParams }: { eventName: string }) => {
  const searchParams = new URLSearchParams({ ...eventParams });
  return fetch(`https://analytics-api.cloudinary.com/${eventName}?${searchParams.toString()}`);
};

const getMaxCollectionsByAssetCountMax = (collections: CollectionInfo[]): CollectionInfo[] => {
  let assetsCounter = 0;

  if (collections[0].resourceCount > MAX_ASSET_COUNT_TO_LOAD) return [collections[0]];

  const nextCollectionsToLoad = collections.filter((collection: CollectionInfo) => {
    assetsCounter += collection.resourceCount;

    return assetsCounter < MAX_ASSET_COUNT_TO_LOAD;
  });

  return nextCollectionsToLoad;
};

const getNextCollectionIdsToLoad = (pageParam: string, collectionsInfo: CollectionInfo[], lastCollectionIdToLoad?: string) => {
  const pageParamIndex = collectionsInfo.findIndex((collection) => collection.id === pageParam);
  const lastCollectionIdToLoadIndex = lastCollectionIdToLoad
    ? collectionsInfo.findIndex((collection) => collection.id === lastCollectionIdToLoad) + 1
    : undefined;
  const unloadedCollections = pageParam ? collectionsInfo.slice(pageParamIndex, lastCollectionIdToLoadIndex) : collectionsInfo;

  const nextCollectionsToLoad = lastCollectionIdToLoad ? unloadedCollections : getMaxCollectionsByAssetCountMax(unloadedCollections);
  const nextCollectionIdsToLoad = nextCollectionsToLoad.map((collection: CollectionInfo) => collection.id);
  return nextCollectionIdsToLoad;
};

export const fetchCollections =
  (collectionsInfo: CollectionInfo[], lastCollectionIdToLoad?: string) =>
  async ({ pageParam }: QueryFunctionContext & { pageParam: string }) => {
    const collectionsIds = getNextCollectionIdsToLoad(pageParam, collectionsInfo, lastCollectionIdToLoad);
    const optionalNextCursorIndex = collectionsInfo.findIndex((collection) => collection.id === collectionsIds[collectionsIds.length - 1]) + 1;
    const nextCursor = optionalNextCursorIndex < collectionsInfo.length ? collectionsInfo[optionalNextCursorIndex].id : undefined;
    const loadedCollections = await getCollections(collectionsIds);
    // eslint-disable-next-line
    // @ts-ignore
    const sortedCollections = loadedCollections.sort((a, b) => collectionsIds.indexOf(a.id) - collectionsIds.indexOf(b.id));
    return { collections: sortedCollections, nextCursor };
  };

export const flatDataPages = (data?: { pages: PortalData[]; pageParam: string }) => {
  if (!data) return [];
  return data.pages.reduce((allCollections: CollectionInfo[], curPage: PortalData) => {
    return [...allCollections, ...curPage.collections];
  }, []);
};
