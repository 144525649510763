import { AssetType } from '../AssetType';
import { ResourceType } from '../ResourceType';
import { ResourceSubtype } from '../ResourceSubtype';

/**
 * Normalizes the asset type and subtype into a single asset type.
 *
 * Data from backend has the same resource type for audio and video assets: 'video'.
 * In order to differentiate one from another you have to use the resource subtype.
 *
 * @param resourceType of the asset
 * @param resourceSubtype of the asset
 * @returns normalized asset type
 */
export const normalizeAssetType = (resourceType: string, resourceSubtype?: string | null) => {
  if (resourceType === ResourceType.Image) {
    return AssetType.Image;
  } else if (resourceType === ResourceType.Video) {
    return resourceSubtype === ResourceSubtype.Audio ? AssetType.Audio : AssetType.Video;
  } else {
    return AssetType.Raw;
  }
};
