import { rgba } from 'polished';
import styled from 'styled-components';
import { combineValues } from '@clds/component-theme';
import { drawerTheme } from './theme';

interface BackdropCoverProps {
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

const BackdropCover = styled.div.attrs<BackdropCoverProps>((props) => ({ onClick: props.onClick }))`
  background: ${combineValues(drawerTheme.backdrop.background.color, (color) => rgba(color, 0.5))};
  flex-grow: 1;
`;

export default BackdropCover;
