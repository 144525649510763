import React, { ReactNode } from 'react';

const getDivEventListenersProperties = () => {
  return [
    'onCopy',
    'onCut',
    'onPaste',
    'onCompositionEnd',
    'onCompositionStart',
    'onCompositionUpdate',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onFocus',
    'onBlur',
    'onChange',
    'onInput',
    'onInvalid',
    'onReset',
    'onSubmit',
    'onError',
    'onLoad',
    'onClick',
    'onContextMenu',
    'onDoubleClick',
    'onDrag',
    'onDragEnd',
    'onDragEnter',
    'onDragExit',
    'onDragLeave',
    'onDragOver',
    'onDragStart',
    'onDrop',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onPointerDown',
    'onPointerMove',
    'onPointerUp',
    'onPointerCancel',
    'onGotPointerCapture',
    'onLostPointerCapture',
    'onPointerEnter',
    'onPointerLeave',
    'onPointerOver',
    'onPointerOut',
    'onSelect',
    'onTouchCancel',
    'onTouchEnd',
    'onTouchMove',
    'onTouchStart',
    'onScroll',
    'onWheel',
    'onAbort',
    'onCanPlay',
    'onCanPlayThrough',
    'onDurationChange',
    'onEmptied',
    'onEncrypted',
    'onEnded',
    'onLoadedData',
    'onLoadedMetadata',
    'onLoadStart',
    'onPause',
    'onPlay',
    'onPlaying',
    'onProgress',
    'onRateChange',
    'onSeeked',
    'onSeeking',
    'onStalled',
    'onSuspend',
    'onTimeUpdate',
    'onVolumeChange',
    'onWaiting',
    'onLoad',
    'onAnimationStart',
    'onAnimationEnd',
    'onAnimationIteration',
    'onTransitionEnd',
    'onToggle',
  ];
};

const getStopPropagationListeners = () => {
  const stopPropagation = (event: Event) => event.stopPropagation();
  const listeners: Record<string, (event: Event) => void> = {};
  for (const eventListenerProperty of getDivEventListenersProperties()) {
    listeners[eventListenerProperty] = stopPropagation;
  }
  return listeners;
};

const stopPropagationListeners = getStopPropagationListeners();
export const StopEventsPropagation = React.memo(({ children }: { children: ReactNode }) => {
  return <div {...stopPropagationListeners}>{children}</div>;
});
