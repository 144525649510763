import { useState, useEffect, useContext } from 'react';
import { IntlConfig } from 'react-intl';
import { defaultLocale } from './constants';
import { LocaleContext } from './locale-context';

export interface Translation {
  messages?: IntlConfig['messages'];
  locale: IntlConfig['locale'];
}

/**
 * Important! This is needed so dynamic import know location of your files
 */
export type LoadMessages = (locale: string) => Promise<Translation['messages']>;

/**
 * Encapsulates logic getting translation files from the server.
 * Use LanguageProvider to provide those translations to components
 */
export const useTranslation = (loadMessages: LoadMessages): Translation => {
  const locale = useContext(LocaleContext);
  const desiredLocale = locale.replace('_', '-');
  const [loadedData, setLoadedData] = useState<Translation>({
    messages: undefined,
    locale: defaultLocale,
  });

  useEffect(() => {
    if (!desiredLocale || desiredLocale === defaultLocale) {
      setLoadedData({
        locale: defaultLocale,
        messages: undefined,
      });
      return;
    }

    loadMessages(desiredLocale)
      .then((loadedMessagesModule) => {
        setLoadedData({
          locale: desiredLocale,
          messages: loadedMessagesModule,
        });
      })
      .catch(() => {
        console.warn(`Missing translation: ${desiredLocale}`);
        setLoadedData({
          locale: desiredLocale,
          messages: undefined,
        });
      });
  }, [desiredLocale, loadMessages]);

  return loadedData;
};
