import useControlledState from '@quid/react-use-controlled-state';
import { unselect, Unselect } from '@clds/compound-components';
import { MenuState } from '@clds/menu';
import { useResetSelectionOnOpen } from './useResetSelectionOnOpen';

export interface DropdownMenuStateProps {
  defaultOpened?: boolean;
  isOpened?: boolean;
  onOpenedChange?: (value: boolean) => void;
}

export type DropdownMenuState = ReturnType<typeof useDropdownMenuState>;

export const useDropdownMenuState = (props: DropdownMenuStateProps, menuState: MenuState) => {
  const [isOpened, setOpened] = useControlledState<boolean>(props.defaultOpened, props.isOpened, props.onOpenedChange);

  const select = (id: string | Unselect, autoClose: boolean) => {
    menuState.select(id);
    if (id === unselect || autoClose) {
      setOpened(false);
    }
  };

  useResetSelectionOnOpen(isOpened, menuState.selectedId, menuState.setFocusedId);

  return {
    isOpened,
    setOpened,
    ...menuState,
    select, // select has to override menu state select prop!
  } as const;
};
