import React, { forwardRef, memo } from 'react';
import { asStyledComponent } from '@clds/component-enhancer';
import { formatBytes } from '@cld/format-bytes';

export interface AssetSizeProps {
  bytes: number;
  decimals?: number;
}

export const AssetSize = forwardRef<HTMLDivElement, AssetSizeProps>(({ bytes, decimals, ...props }, ref) => {
  const isValid = typeof bytes === 'number' && bytes >= 0;

  if (!isValid) {
    return (
      <div ref={ref} {...props} data-test="file-size">
        <span data-test="file-size-value">NaN</span>
      </div>
    );
  }

  const { value, unit } = formatBytes(bytes, decimals);

  return (
    <div ref={ref} {...props} data-test="file-size">
      <span data-test="file-size-value">{value}</span>
      {unit && <span data-test="file-size-unit">{unit}</span>}
    </div>
  );
});

export default asStyledComponent(memo(AssetSize));
