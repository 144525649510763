import React, { ReactNode, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { blenderCss } from '@clds/blender';
import { Item } from '@clds/item';
import { NavigationLayout } from './NavigationLayout';
import { NavigationLayoutLink, NavigationLayoutLinkProps } from './NavigationLayoutLink';

const RightSlotWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${globalTheme.spacing.sm};
  margin-left: auto;

  > *:not(:last-child) {
    margin-right: ${globalTheme.spacing.sm};
  }
`;

const ChildrenWrapper = styled.div`
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;

  ${blenderCss.typographyFromCssVariables('sm')};

  color: ${globalTheme.palette.contrastHigh};
`;

export interface NavigationMenuItemProps<T extends React.ComponentType> {
  children?: ReactNode;
  isSelected?: boolean;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  onClick?: () => void;
  linkProps?: NavigationLayoutLinkProps<T>;
  className?: string;
  verticalPadding?: 'xxs' | 'none';
}

const StyledItem = styled(Item)`
  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;
    `}
`;

const StyledNavigationLayoutLink = styled(NavigationLayoutLink)``;

const StyledListItem = styled.li<{ isSelected?: boolean }>`
  :hover ${StyledItem} {
    background: ${globalTheme.palette.backgroundAlt};
  }

  ${StyledItem} {
    ${({ isSelected }) =>
      isSelected &&
      css`
      {
      font-weight: 700;
      background: #3448c51f;

      :hover {
        background: #3448c51f;
      }}
        
        ${StyledNavigationLayoutLink}:before {
          box-shadow: none;
        }
    `};
  }
`;

/**
 * Dedicated abstraction that binds routing and layout functionality
 */
export const NavigationMenuItem = <T extends React.ComponentType>({
  children,
  isSelected,
  leftSlot,
  rightSlot,
  onClick,
  linkProps,
  verticalPadding,
  ...rest
}: NavigationMenuItemProps<T>) => {
  const handleClick: React.EventHandler<SyntheticEvent> = (e) => {
    if (onClick) {
      onClick();
      e.preventDefault();
    }
  };
  return (
    <StyledListItem {...rest} isSelected={isSelected}>
      <StyledItem isSelected={isSelected} role="presentation">
        <NavigationLayout verticalPadding={verticalPadding}>
          <StyledNavigationLayoutLink href="#" {...linkProps} onClick={handleClick} leftSlot={leftSlot}>
            <ChildrenWrapper>{children}</ChildrenWrapper>
            {rightSlot && <RightSlotWrapper>{rightSlot}</RightSlotWrapper>}
          </StyledNavigationLayoutLink>
        </NavigationLayout>
      </StyledItem>
    </StyledListItem>
  );
};
