import styled, { css } from 'styled-components';
import { isSafari } from './utils';

export const ImageFit = styled.div`
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  min-height: 0;
  ${isSafari() &&
  css`
    flex-direction: column;
  `};

  > img,
  > video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-self: center;
  }
`;
