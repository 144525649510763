import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';

export const StyledSomethingWentWrongWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  a {
    ${typography.sm.highContrast};
    color: ${globalTheme.palette.primary};
    cursor: pointer;
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${globalTheme.spacing.md};
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: ${globalTheme.spacing.xl};
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled.div`
  ${typography.xl.regular};
  margin-bottom: ${globalTheme.spacing.sm};
`;

export const StyledSubTitle = styled.div`
  ${typography.sm.regular};
`;

export const StyledContentTitle = styled.div`
  ${typography.sm.highContrast};
`;

export const StyledList = styled.ul`
  padding: 0 ${globalTheme.spacing.md};
`;

export const StyledListItem = styled.li`
  ${typography.sm.regular};
  margin-bottom: ${globalTheme.spacing.xs};
`;

export const StyledImage = styled.img``;

export const StyledSomethingWentWrongVertical = styled(StyledSomethingWentWrongWrapper)`
  ${StyledContentWrapper} {
    flex-direction: column-reverse;
  }

  ${StyledHeader} {
    text-align: center;
  }

  img {
    margin-bottom: ${globalTheme.spacing.xxl};
  }
`;
