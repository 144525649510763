import React, { ReactNode, useCallback, useRef, useState } from 'react';
import Collapsible, { CollapsibleProps } from 'react-collapsible';
import styled from 'styled-components';

const Root = styled.div`
  &[data-hide-content='true'] .Collapsible__contentInner {
    display: none;
  }
`;

export interface CollapsiblePanelProps extends Pick<CollapsibleProps, 'trigger'> {
  onExpandedChange: (isOpen: boolean) => void;
  isOpened: boolean;
  children?: ReactNode;
  className?: string;
}

export const CollapsiblePanel = ({ trigger, onExpandedChange, children, isOpened, ...props }: CollapsiblePanelProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [hasClosed, setHasClosed] = useState(!isOpened);

  const scrollIntoView = useCallback(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [containerRef]);

  return (
    <Root {...props} data-hide-content={!isOpened && hasClosed} ref={containerRef}>
      <Collapsible
        overflowWhenOpen="visible"
        transitionTime={300}
        trigger={trigger}
        open={isOpened}
        onOpening={() => {
          setHasClosed(false);
          onExpandedChange(true);
        }}
        onOpen={scrollIntoView}
        onClosing={() => {
          onExpandedChange(false);
        }}
        onClose={() => setHasClosed(true)}
      >
        {children}
      </Collapsible>
    </Root>
  );
};
