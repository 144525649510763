// istanbul ignore file
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { FoundationContextProvider, SurfaceContextProvider } from '@clds/design-system-foundations';
import { useItemContext } from '@clds/item';

interface RootProps {
  verticalPadding: 'xxs' | 'none';
}

const Root = styled.div<RootProps>`
  display: flex;
  align-items: center;
  padding: ${({ verticalPadding }) => (verticalPadding === 'none' ? 0 : globalTheme.spacing[verticalPadding])} 0;

  > * {
    flex-grow: 1;
  }
`;

export interface NavigationLayoutProps extends Partial<RootProps> {
  children?: ReactNode;
  className?: string;
}

export const NavigationLayout = ({ children, verticalPadding = 'xxs', ...rest }: NavigationLayoutProps) => {
  const { variant, selectionVariant, isSelected } = useItemContext();
  const surfaceTone = isSelected ? selectionVariant : 'subtle';

  return (
    <Root verticalPadding={verticalPadding} {...rest}>
      <SurfaceContextProvider variant={variant} tone={surfaceTone}>
        <FoundationContextProvider size="md">{children}</FoundationContextProvider>
      </SurfaceContextProvider>
    </Root>
  );
};
