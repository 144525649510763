import styled, { keyframes } from 'styled-components';
import { typography } from '@clds/typography';
import { globalTheme } from '@clds/component-theme';
import { media, usePhone } from '@clds/style-utils';
import { alignmentToJustifyContent } from './header.consts';
import { PortalLogo } from '../../services/dataService/PortalData';

const COLLAPSED_HEADER_HEIGHT = '40px';
const EXPANDED_HEADER_HEIGHT = '1000px';

enum LOGO_SIZES {
  small = '40px',
  medium = '80px',
  large = '120px',
}

export const StyledLanguageSelectorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${globalTheme.palette.surfaceAlt};
`;

export const LogoContainer = styled.div<{ logo: PortalLogo }>`
  display: flex;
  width: 100%;
  justify-content: ${({ logo }) => !!logo?.alignment && alignmentToJustifyContent[logo?.alignment]};
  height: ${({ logo }) => LOGO_SIZES[logo?.size as keyof typeof LOGO_SIZES]};
`;

export const StyledTitle = styled.div.attrs({ 'data-test': 'portal-header-title' })`
  display: flex;
  ${typography.md.highContrast};

  @media ${media.phone()} {
    display: none;
  }
`;

export const StyledExpandedTitle = styled.div.attrs<{ logo: PortalLogo }>({ 'data-test': 'portal-header-title' })<{ logo?: PortalLogo | null }>`
  display: flex;
  ${typography.lg.regular};
  font-weight: 600;

  @media ${media.phone()} {
    ${typography.xl.regular};
    padding: ${globalTheme.spacing.lg} ${globalTheme.spacing.lg} 0;
    width: 100%;
    justify-content: center;
    > span {
      white-space: normal;
      text-align: center;
      text-overflow: inherit;
    }
  }

  align-self: ${({ logo }) => (logo?.alignment ? alignmentToJustifyContent[logo?.alignment] : 'center')};
`;

export const CollapsedLogoContainer = styled.div<{ logo: PortalLogo }>`
  display: flex;
  height: ${COLLAPSED_HEADER_HEIGHT};
  width: 100%;
  justify-content: ${({ logo }) => !!logo?.alignment && alignmentToJustifyContent[logo?.alignment]};
`;

export const StyledDescription = styled.div`
  ${typography.sm.regular};
  > p {
    margin: 0;
    text-align: center;
  }
  @media ${media.phone()} {
    ${typography.sm.regular};
    padding: ${globalTheme.spacing.lg} ${globalTheme.spacing.lg} 0;
  }
`;

export const Holder = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: ${globalTheme.spacing.xxl};
  background-color: ${globalTheme.palette.surfaceAlt};
  transition: max-height 0.4s ease-in-out;
  max-height: ${({ isCollapsed }) => (isCollapsed ? COLLAPSED_HEADER_HEIGHT : EXPANDED_HEADER_HEIGHT)};
  padding: ${globalTheme.spacing.lg};
  box-sizing: content-box;
  gap: ${globalTheme.spacing.md};
  overflow: hidden;
`;

export const StyledImg = styled.img`
  height: 100%;
`;

export const StyledLogoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.md};
  height: 100%;
`;

export const StyledRightSlot = styled.div`
  display: flex;
  align-items: center;
  height: ${COLLAPSED_HEADER_HEIGHT};
  align-self: flex-end;
  justify-self: flex-end;
`;

export const StyledCollapsedHeader = styled.div<{ isCollapsed: boolean }>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'flex' : 'none')};
  align-items: center;
`;

export const StyledHeaderTopSection = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  justify-content: ${({ isCollapsed }) => (isCollapsed ? 'space-between' : 'flex-end')};
  width: 100%;
`;

export const StyledExpandedHeader = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};
  align-items: center;
  gap: ${globalTheme.spacing.sm};
  overflow: hidden;
`;
