import React from 'react';
import styled from 'styled-components';
import { combineValues, globalTheme } from '@clds/component-theme';
import { Icon } from '@cld/icon';
import { t } from '../AssetCardTheme';

export const AssetCardError = styled((props) => (
  <div {...props} data-test={'asset-card-error'}>
    <Icon name="error" variant="error" />
  </div>
))`
  position: relative;
  border-radius: ${globalTheme.radius.lg};
  background: ${t.background.color.error};
  ${Icon} {
    position: absolute;
    width: ${t.icon.size}px;
    height: ${t.icon.size}px;
    top: ${combineValues(t.icon.size, (size) => `calc(50% - ${size / 2}px)`)};
    left: ${combineValues(t.icon.size, (size) => `calc(50% - ${size / 2}px)`)};
  }
`;
