// istanbul ignore file
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';

export const getLanguage = () => {
  // necessary default value for SSR apps
  if (typeof navigator === 'undefined') {
    return 'en';
  }

  if (navigator.languages !== undefined) {
    return navigator.languages[0];
  }

  return navigator.language;
};

export const polyfill = (locale: string) => {
  polyfillNumberFormat(locale);
  polyfillPluralRules(locale);
  polyfillRelativeTime(locale);
};

// Polyfills

const polyfillPluralRules = (locale: string) => {
  if (!shouldPolyfillPluralRules()) {
    return;
  }

  require('@formatjs/intl-pluralrules/polyfill');

  switch (locale) {
    default:
      require('@formatjs/intl-pluralrules/locale-data/en');
      break;
    case 'de':
      require('@formatjs/intl-pluralrules/locale-data/de');
      break;
  }
};

const polyfillNumberFormat = (locale: string) => {
  if (!shouldPolyfillNumberFormat()) {
    return;
  }

  require('@formatjs/intl-numberformat/polyfill');

  switch (locale) {
    default:
      require('@formatjs/intl-numberformat/locale-data/en');
      break;
    case 'de':
      require('@formatjs/intl-numberformat/locale-data/de');
      break;
  }
};

const polyfillRelativeTime = (locale: string) => {
  if (!shouldPolyfillRelativeTimeFormat()) {
    return;
  }

  require('@formatjs/intl-relativetimeformat/polyfill');

  switch (locale) {
    default:
      require('@formatjs/intl-relativetimeformat/locale-data/en');
      break;
    case 'de':
      require('@formatjs/intl-relativetimeformat/locale-data/de');
      break;
  }
};
