import Progress from '@clds/progress';
import { useInView } from 'react-intersection-observer';
import { Events, scrollSpy, scroller } from 'react-scroll';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { MainLayout } from './MainLayout';
import Navigation from './Navigation/Navigation';
import { Root, StyledProgressWrapper } from './App.styles';
import { CollectionsAccordion } from './CollectionsAccordion';
import { COLLECTIONS_CONTAINER_ID, COLLECTIONS_LIST_ID } from './App.constants';
import { useCloudinaryTracker } from '../common/hooks/useCloudinaryTracker';
import { fetchCollections } from '../services/dataService/dataService';
import { useLoadCollections } from '../services/dataService/hooks';
import { getQueryParam } from '../common/helpers/urlHelpers';
import { COLLECTION_QUERY_PARAM } from '../constants/queryParams';

export const BrandPortalContent = ({
  collectionsInfo,
  portalName,
  portalDescription,
  portalLogo,
  downloadPresets,
  publicLinkId,
  preventDownloadOriginal,
}) => {
  const contentRef = useRef();
  const queryClient = useQueryClient();
  const cloudinaryTracker = useCloudinaryTracker();
  const [isScrollingFromSideMenu, setIsScrollingFromSideMenu] = useState(false);
  const [activeCollectionId, setActiveCollectionId] = useState(collectionsInfo?.[0]?.id);
  const [isPendingCollectionNavigation, setIsPendingCollectionNavigation] = useState(true);
  const { data, allFetchedCollections, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching } = useLoadCollections(collectionsInfo);

  const isFirstPageLoaded = data?.pages?.[0];

  // If the first page was loaded, we can execute navigation based on query param if there is
  useEffect(() => {
    const collectionIdFromQuery = getQueryParam(COLLECTION_QUERY_PARAM);
    if (isFirstPageLoaded && collectionIdFromQuery) {
      collectionIdFromQuery && navigateToCollection(collectionIdFromQuery);
    } else {
      setIsPendingCollectionNavigation(false);
    }
  }, [isFirstPageLoaded]);

  // The progress element is observed when intersect with the viewport,
  // when it's in view, we are fetching next page
  const { ref: progressRef, inView } = useInView();
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    Events.scrollEvent.register('begin', (to, element) => {
      setIsScrollingFromSideMenu(true);
      element.scrollIntoView({ block: 'start' });
    });

    Events.scrollEvent.register('end', (to) => {
      setActiveCollectionId(to);
      setIsScrollingFromSideMenu(false);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, [isScrollingFromSideMenu, activeCollectionId]);

  // When navigates to an unloaded collection, either through click in navigation or through url,
  // We fetch all the collections previous to the collection we navigate to
  const fetchPreviousUnloadedCollections = async (lastCollectionId) => {
    const pageParam = data?.pages?.[data.pages.length - 1]?.nextCursor;

    const loadedCollections = await fetchCollections(collectionsInfo, lastCollectionId)({ pageParam });
    queryClient.setQueryData(['collections'], (data) => ({
      pages: [...data.pages, loadedCollections],
      pageParams: [...data.pageParams, loadedCollections.nextCursor],
    }));
  };

  const navigateToUnloadedCollection = async (collectionId) => {
    try {
      setIsPendingCollectionNavigation(true);
      await fetchPreviousUnloadedCollections(collectionId);
      setIsPendingCollectionNavigation(false);
    } finally {
      setIsPendingCollectionNavigation(false);
    }

    // Since we load and scroll to the element on the same function,
    // We execute the scroll after the next paint for the element to render first
    setTimeout(() => {
      scroller.scrollTo(collectionId, {
        duration: 500,
        containerId: COLLECTIONS_LIST_ID,
        isDynamic: true,
        offset: 50,
      });
    }, 0);
  };

  const navigateToCollection = async (requestedCollectionId) => {
    const isCollectionExist = !!collectionsInfo.find((collection) => collection.id === requestedCollectionId);
    if (!requestedCollectionId || !isCollectionExist) return;
    const isCollectionLoaded = !!allFetchedCollections.find((collection) => collection.id === requestedCollectionId);

    if (isCollectionLoaded) {
      scroller.scrollTo(requestedCollectionId, {
        duration: 500,
        containerId: COLLECTIONS_LIST_ID,
        isDynamic: true,
        offset: 50,
      });
    } else {
      return await navigateToUnloadedCollection(requestedCollectionId);
    }
  };

  const getNavigationMenuItems = useCallback(() => {
    return (
      collectionsInfo &&
      collectionsInfo.map((collection) => {
        return { value: collection.name, id: collection.id };
      })
    );
  }, [collectionsInfo, allFetchedCollections]);

  const wasPageViewEventReported = useRef(false);
  useEffect(() => {
    if (!wasPageViewEventReported.current) {
      wasPageViewEventReported.current = true;
      cloudinaryTracker({ eventName: 'portal_page_view' });
    }
  }, [cloudinaryTracker]);

  const renderInfiniteScrollProgress = () =>
    hasNextPage && (
      <StyledProgressWrapper ref={progressRef}>
        {isFetchingNextPage && hasNextPage ? <Progress.Circular variant="primary" size="lg" /> : null}
      </StyledProgressWrapper>
    );

  return (
    <Root>
      <MainLayout
        id={COLLECTIONS_CONTAINER_ID}
        contentRef={contentRef}
        name={portalName}
        description={portalDescription}
        logo={portalLogo}
        isPendingCollectionNavigation={isFetching || isPendingCollectionNavigation}
        navigation={
          collectionsInfo && <Navigation activeCollectionId={activeCollectionId} onItemClick={navigateToCollection} items={getNavigationMenuItems()} />
        }
        content={
          <CollectionsAccordion
            contentRef={contentRef}
            allFetchedCollections={allFetchedCollections}
            isScrollingFromSideMenu={isScrollingFromSideMenu}
            downloadPresets={downloadPresets}
            preventDownloadOriginal={preventDownloadOriginal}
            publicLinkId={publicLinkId}
            setActiveCollectionId={setActiveCollectionId}
            renderProgress={renderInfiniteScrollProgress}
          />
        }
      />
    </Root>
  );
};
