/* istanbul ignore file */
import { useCallback, useMemo } from 'react';
import { usePersistency } from '@cld/use-persistency';
import { supportedLanguages } from '@cld/intl';

export const useSupportedLanguages = () => {
  const persistenceKey = 'userLocale';
  const { setPersistentValue } = usePersistency(persistenceKey);

  const languages = useMemo(
    () =>
      Object.keys(supportedLanguages).map((languageKey) => ({
        languageKey,
        languageLabel: supportedLanguages[languageKey],
      })),
    []
  );

  const setLocale = useCallback(
    (locale: string) => {
      setPersistentValue(locale);
      window.location.reload();
    },
    [setPersistentValue]
  );

  return {
    languages,
    setLocale,
  };
};
