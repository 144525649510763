import { useEffect, useRef, useMemo } from 'react';
import lottie, { AnimationConfigWithPath, AnimationConfigWithData, AnimationItem } from 'lottie-web';

export type UseAnimationProps = Omit<AnimationConfigWithPath, 'container'> | Omit<AnimationConfigWithData, 'container'>;
export const useAnimation = (props: UseAnimationProps) => {
  const animationItemRef = useRef<AnimationItem | null>(null);
  const animationRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    animationItemRef.current = lottie.loadAnimation({
      ...props,
      container: animationRef.current as Element,
    });
    return () => animationItemRef.current?.destroy();
  }, [props]);

  return useMemo(
    () => ({
      animationItemRef,
      animationRef,
    }),
    []
  );
};
