import { useIntl } from 'react-intl';
import { useMemo } from 'react';

const year = new Date().getFullYear();
const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const useMonthNames = () => {
  const { formatDate } = useIntl();
  return useMemo(() => monthList.map((monthIndex) => formatDate(new Date(year, monthIndex), { month: 'long' })), [formatDate]);
};
