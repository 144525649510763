/* istanbul ignore file */
import { getProperties } from '@cld/console-apps-services';
import { validateResponseStatus } from './helpers';
import { sharedUrl } from './api.constants';

const buildRequestUrl = (
  publicLinkId: string,
  customerId: string,
  transformationName?: string,
  resourceId?: string,
  collectionId?: string,
  searchLuceneQueryString?: string
) => {
  const urlParams = {
    public_link_id: publicLinkId,
    ...(searchLuceneQueryString && { expression: searchLuceneQueryString }),
    ...(transformationName && { transformation_name: transformationName }),
    ...(resourceId && { resource_id: resourceId }),
    ...(collectionId && { portal_collection_id: collectionId }),
  };
  const downloadRequestURLSearchParams = new URLSearchParams(urlParams);
  return `${sharedUrl}/public/${customerId}/download?${downloadRequestURLSearchParams.toString()}`;
};

export const getBuildDownloadUrl = async (
  publicLinkId: string,
  transformationName?: string,
  externalId?: string,
  collectionId?: string,
  searchLuceneQueryString?: string
) => {
  const { customerId } = getProperties();

  if (!customerId) {
    throw new Error('corrupted properties');
  }

  const requestURL = buildRequestUrl(publicLinkId, customerId, transformationName, externalId, collectionId, searchLuceneQueryString);
  const response: Response = await fetch(requestURL);
  validateResponseStatus(response);

  const presetDownloadUrl = (await response.json()) as Record<string, string>;
  return presetDownloadUrl;
};
