import formatDuration from 'format-duration';
import React, { memo, FC } from 'react';
import styled from 'styled-components';
import { globalTheme, GlobalTheme, useMixin } from '@clds/component-theme';
import { usePhone } from '@clds/style-utils';
import Tooltip from '@clds/tooltip';
import AssetDimensions from '@cld/asset-dimensions';
import { AssetTypeIcon } from '@cld/asset-type';
import AssetSize from '@cld/asset-size';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-width: 0;
  height: 100%;
`;

const TitleRow = styled.div<{ color: keyof GlobalTheme['palette'] }>`
  display: flex;
  flex-direction: row;

  font-size: ${globalTheme.font.size.md};
  color: ${({ color }) => globalTheme.palette[color]};
  font-weight: 500;

  ${AssetTypeIcon} {
    margin-right: ${globalTheme.spacing.sm};
  }
`;

const MetadataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${globalTheme.spacing.xs};

  font-size: ${globalTheme.font.size.xxs};
  color: ${globalTheme.palette.contrastLow};

  ${AssetSize}, ${AssetDimensions} {
    margin-right: ${globalTheme.spacing.xs};
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Duration = ({ duration }: { duration?: number | null }) => <div data-test="duration">{formatDuration(duration * 1000)}</div>;
const isValidDimension = (height: number, width: number) => height > 0 && width > 0;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isValidDuration = (duration?: number | null) => duration > 0;

export interface AssetMetadataVerticalProps {
  type: 'audio' | 'image' | 'video' | 'raw';
  filename: string;
  bytes: number;
  height?: number | string;
  width?: number | string;
  duration?: number | null;
  color: keyof GlobalTheme['palette'];
}

const AssetMetadataVertical = styled<FC<AssetMetadataVerticalProps>>(
  memo(({ type, filename, bytes, height, width, duration, color, ...rest }) => {
    const isPhone = usePhone();
    const iconsColor = useMixin(globalTheme.palette.contrastHigh);
    return (
      <Wrapper data-test="asset-metadata" {...rest}>
        <TitleRow color={color}>
          <AssetTypeIcon type={type} color={iconsColor} />
          <Tooltip maxWidth="lg" placement="bottomRight" title={filename}>
            <Tooltip.OverflowTrigger>{filename}</Tooltip.OverflowTrigger>
          </Tooltip>
        </TitleRow>
        {!isPhone && (
          <MetadataRow>
            <AssetSize key="assetSize" bytes={bytes} />
            {isValidDimension(Number(height), Number(width)) && <AssetDimensions key="assetDimensions" height={Number(height)} width={Number(width)} />}
            {isValidDuration(duration) && <Duration key="assetDuration" duration={duration} />}
          </MetadataRow>
        )}
      </Wrapper>
    );
  })
)``;

export default AssetMetadataVertical;
