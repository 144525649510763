import { createTheme, globalTheme } from '@clds/component-theme';

export const emojiPickerTheme = createTheme({
  container: {
    background: {
      color: globalTheme.palette.surface,
    },
    border: {
      color: globalTheme.palette.secondaryAlt,
    },
    shadow: globalTheme.shadow.md,
    font: globalTheme.globals.font.family,
  },
  toolbar: {
    hover: {
      color: globalTheme.palette.primary,
    },
    color: globalTheme.palette.contrastLow,
  },
  input: {
    background: {
      color: globalTheme.palette.surfaceAlt,
    },
    icon: {
      layout: {
        top: globalTheme.spacing.xs,
      },
    },
  },
});
