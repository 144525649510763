import React, { ReactNode } from 'react';

export const ALL_SYSTEMS_OPERATIONAL_URL = 'https://status.cloudinary.com';

export const SUPPORT_REQUEST_URL = 'https://support.cloudinary.com/hc/en-us/requests/new';

export const ERROR_DOG_URL = 'https://res.cloudinary.com/prod/image/upload/f_auto,q_auto/mpex/errorDog.svg';

export const getLink = (href: string | undefined, onClick?: () => void) => {
  return (children: ReactNode) => (
    <a href={href} onClick={onClick} target={href ? '_blank' : undefined} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const onClickRefresh = getLink(undefined, () => window.location.reload());

export interface SomethingWentWrongProps {
  title?: string;
  subTitle?: ReactNode;
  imageSrc?: string;
  imageHeight?: number;
  imageWidth?: number;
}

export interface SomethingWentWrongResponsive extends SomethingWentWrongProps {
  isMobile?: boolean;
}
