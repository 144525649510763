import { ResourceType, ResourceSubtype, UploadTypeValues, richTextSchema, ResourceStatus, ResourceSubStatus } from '@cld/console-apps-types';
import * as yup from '@cld/yup-extra';

export const portalCollectionsSchema = yup
  .object({
    collections: yup
      .array(
        yup
          .object({
            id: yup.string().required(),
            name: yup.string().required(),
            description: richTextSchema,
            preferences: yup
              .object({
                locale: yup.string().nullable().optional(),
                preventDownloadOriginal: yup.boolean().optional(),
              })
              .from('prevent_download_original', 'preventDownloadOriginal')
              .optional(),
            resources: yup
              .object({
                assets: yup
                  .array(
                    yup
                      .object({
                        publicId: yup.string().required(),
                        displayName: yup.string().nullable(),
                        format: yup.string().nullable(),
                        resourceType: yup.mixed().oneOf(Object.values(ResourceType)).required(),
                        resourceSubtype: yup.mixed().oneOf([...Object.values(ResourceSubtype), null]),
                        uploadType: yup.mixed().oneOf(UploadTypeValues).required(),
                        urls: yup
                          .object({
                            original: yup.string().required(),
                            originalDownload: yup.string().notRequired(),
                            preview: yup.string().required(),
                            thumbnail: yup.string().required(),
                          })
                          .from('original_download', 'originalDownload')
                          .required(),
                        status: yup.mixed().oneOf(Object.values(ResourceStatus)).optional().default(ResourceStatus.Active),
                        subStatus: yup.mixed().oneOf([...Object.values(ResourceSubStatus), null]),
                        externalId: yup.string().required(),
                      })
                      .from('delivery_urls', 'urls')
                      .from('public_id', 'publicId')
                      .from('resource_type', 'resourceType')
                      .from('display_name', 'displayName')
                      .from('resource_subtype', 'resourceSubtype')
                      .from('type', 'uploadType')
                      .from('substatus', 'subStatus')
                      .from('external_id', 'externalId')
                      .required()
                  )
                  .defined(),
              })
              .from('resources', 'assets')
              .required(),
          })
          .from('collection_description', 'description')
          .from('resource_list', 'resources')
          .required()
      )
      .defined(),
  })
  .from('title', 'name')
  .required();

export type PortalCollectionsDto = yup.InferType<typeof portalCollectionsSchema>;
