import { Descendant } from 'slate';
import { Icons } from '@cld/icon';
import { FormatOption } from './types';

const FORMAT_OPTIONS: FormatOption = {
  bold: {
    type: 'bold',
    name: 'Bold',
  },
  underline: {
    type: 'underline',
    name: 'Underline',
  },
  italic: {
    type: 'italic',
    name: 'Italic',
  },
  strike: {
    type: 'strike',
    name: 'Strikethrough',
  },
  code: {
    type: 'code',
    name: 'Insert Code',
  },
  bulletedList: {
    type: 'bulletedList',
    identifier: 'bulleted-list',
    name: 'Bullet List',
  },
  numberedList: {
    type: 'numberedList',
    identifier: 'numbered-list',
    name: 'Numbered List',
  },
  face: {
    type: 'face',
    name: 'Insert Emoji',
  },
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const DEFAULT_FORMAT_OPTIONS: Record<string, Icons[]> = {
  basic: ['bold', 'italic', 'underline', 'strike'],
  list: ['numberedList', 'bulletedList'],
  code: ['code'],
  face: ['face'],
};

const EDITOR_INITIAL_VALUE: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const EDITOR_INTIAL_PLACEHOLDER = 'Add your description here.';

const EMOJI_SETTINGS = {
  text: 'Choose your emoji..',
  emojiPlaceholder: 'point_up',
};

const NAV_KEYS = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Shift', 'Control', 'Alt', 'Meta', 'Enter', 'Tab'];
const ALLOWD_KEYS_EMOJI_FIX = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Shift', 'Control', 'Alt', 'Meta', 'Tab'];

export {
  EDITOR_INITIAL_VALUE,
  FORMAT_OPTIONS,
  DEFAULT_FORMAT_OPTIONS,
  LIST_TYPES,
  EMOJI_SETTINGS,
  EDITOR_INTIAL_PLACEHOLDER,
  NAV_KEYS,
  ALLOWD_KEYS_EMOJI_FIX,
};
