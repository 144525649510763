import React, { FC } from 'react';
import styled from 'styled-components';
import { globalTheme, GlobalTheme } from '@clds/component-theme';
import { asStyledComponent } from '@clds/component-enhancer';
import useFormat from './useFormat';

const ColoredSpan = styled.span<{ color: keyof GlobalTheme['color'] }>`
  color: ${({ color }) => globalTheme.color[color]};
`;

export interface AssetTypeFormatProps {
  color: keyof GlobalTheme['color'];
  format: string;
}

export const AssetTypeFormat: FC<React.PropsWithChildren<AssetTypeFormatProps>> = ({ color, format, ...rest }) => {
  const formattedFormat = useFormat(format);
  return (
    <div data-type="asset-type" {...rest}>
      <ColoredSpan color={color} data-test="asset-type-format">
        {formattedFormat}
      </ColoredSpan>
    </div>
  );
};

export default asStyledComponent(AssetTypeFormat);
