import { MutableRefObject, useLayoutEffect, useRef } from 'react';
import 'focus-options-polyfill'; // polyfill for preventScroll on Safari
import { unselect, Unselect } from '@clds/compound-components';

export const useFocusOnMount = (ref: HTMLElement | null) => ref?.focus({ preventScroll: true });

export const useFocusManagement = (
  menuRef: MutableRefObject<HTMLElement | null>,
  buttonRef: MutableRefObject<HTMLElement | null>,
  opened: boolean,
  focusOnClose: boolean
) => {
  const firstRender = useRef(true);

  useLayoutEffect(() => {
    if (opened) {
      menuRef.current?.focus();
    } else {
      if (!firstRender.current && focusOnClose) {
        buttonRef.current?.focus();
      }
    }
    firstRender.current = false;
  }, [buttonRef, menuRef, opened, focusOnClose]);
};

interface ExplicitSelectionStateProps {
  defaultSelectedId: string | Unselect | undefined;
  selectedId: string | Unselect | undefined;
  onSelect: ((value: string | Unselect) => void) | undefined;
}

const noop = () => void 0;

/** We want to make sure that selection feature is turned off by default
 * We should enable it (controlled or uncontrolled) only if appropriate control props are passed
 * */
export const useExplicitSelectionState = ({ selectedId, defaultSelectedId, onSelect }: ExplicitSelectionStateProps): ExplicitSelectionStateProps => {
  const supportDropdownMenuSelection = defaultSelectedId !== undefined || selectedId !== undefined;

  if (supportDropdownMenuSelection) {
    // for DropdownMenu we have to pass defaultSelectedId or selectedId to make selection even working
    return { defaultSelectedId, selectedId, onSelect };
  }
  // otherwise we disable selection feature at all - not every dropdown requires a selected option
  return { defaultSelectedId: undefined, selectedId: unselect, onSelect: onSelect || noop };
};
