import styled from 'styled-components';
import { captionHeight, imageAspectRatio } from '../../constants/asset';
import { AssetGridIE11Fallback } from './AssetGridIE11Fallback';
const isIe11 = window.navigator.userAgent.indexOf('Trident/7.0') > -1;

const AssetGridImpl = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 20px;
  grid-auto-rows: 1fr;

  // https://css-tricks.com/aspect-ratios-grid-items/
  &::before {
    content: '';
    width: 0;
    padding-bottom: calc(${imageAspectRatio * 100}% + ${captionHeight}px);
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`;

export const AssetGrid = isIe11 ? AssetGridIE11Fallback : AssetGridImpl;
