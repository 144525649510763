import styled from 'styled-components';
import { combineValues } from '@clds/component-theme';
import { drawerSizeVariant } from './theme';
import { getWidth, setPosition } from './utils';
import { DrawerThemeProps } from './types';

type DrawerContainerProps = Pick<DrawerThemeProps, 'position' | 'size' | 'className'>;

const DrawerContainer = styled.div<DrawerContainerProps>`
  width: ${combineValues(
    // TODO: Fix types related to migration to DS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    (props: DrawerContainerProps) => props.position,
    drawerSizeVariant(),
    getWidth
  )};
  height: 100%;
  ${(props: DrawerContainerProps) => props.position && 'position: fixed;'}
  ${(props: DrawerContainerProps) => setPosition(props.position)};
`;

export default DrawerContainer;
