import { rgba } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';
import { combineValues, globalTheme } from '@clds/component-theme';
import { media } from '@clds/style-utils';
import Tooltip from '@clds/tooltip';
import AssetMetadata from '@cld/asset-metadata';
import { AssetCardError } from './cards/AssetCardError';
import { AssetCardSkeleton } from './cards/AssetCardSkeleton';
import { AssetCardRenderer } from './cards/AssetCardRenderer';
import { AssetTypes } from './Asset.types';
import { t } from './AssetCardTheme';

const Root = styled.div.attrs({ 'data-test': 'asset-card' })`
  position: relative;
  display: flex;
  flex-direction: column;
  ${AssetCardRenderer}, ${AssetCardError} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: ${globalTheme.radius.lg};
  }

  ${AssetMetadata} {
    padding: ${globalTheme.spacing.xxs} ${globalTheme.spacing.xs};
  }
`;

const Children = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: ${combineValues(globalTheme.color.black, (color) => rgba(color, 0.5))};
  border-radius: ${globalTheme.radius.lg};
  opacity: 0;
  transition: opacity 0.15s ease-in;
  @media ${media.phone()} {
    background-color: transparent;
    opacity: 1;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  
  &:hover {
  ${Children} {
    opacity: 1;
  }
`;

const AssetCardData = styled.div.attrs({ 'data-test': 'asset-card-data' })`
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    0deg,
    ${combineValues(globalTheme.color.black, (color) => `${rgba(color, 0.8)} 0%, ${rgba(color, 0.13)} 26%, ${rgba(color, 0)} 100%`)}
  );
  border-radius: ${globalTheme.radius.lg};
`;

export interface CaptionProps {
  height: number;
}

const Caption = styled.div.attrs({ 'data-test': 'caption' })<CaptionProps>`
  color: ${t.caption.color};
  font-size: ${globalTheme.font.size.xxs};
  font-weight: bold;
  line-height: ${globalTheme.font.size.sm};

  height: ${({ height }) => height}px;
  display: inline-flex;
  align-items: center;
`;

const renderMetadata: FC<React.PropsWithChildren<Pick<AssetTypes, Exclude<keyof AssetTypes, 'assetSrc'>>>> = ({
  assetType,
  assetFormat,
  assetWidth,
  assetHeight,
  assetBytes,
  assetDuration,
}) => (
  <AssetMetadata
    format={assetFormat}
    type={assetType}
    height={assetHeight}
    width={assetWidth}
    bytes={assetBytes}
    duration={assetDuration as unknown as number}
    color="white"
  />
);

export interface StyledAssetCardSkeletonProps {
  theme: typeof globalTheme;
}

const StyledAssetCardSkeleton = styled(AssetCardSkeleton).attrs(({ theme }: StyledAssetCardSkeletonProps) => ({
  primaryColor: theme.color.gray50,
  secondaryColor: theme.color.gray40,
}))``;

export type AssetCardProps = AssetTypes & {
  'data-test-specifier'?: string;
  loading?: boolean;
  error?: boolean;
  caption?: string;
  captionHeight?: number;
  isLoading?: boolean;
  isError?: boolean;
};

const AssetCard: FC<React.PropsWithChildren<AssetCardProps>> = ({
  'data-test-specifier': dataTestSpecifier = '',
  isLoading,
  isError,
  assetSrc,
  assetType,
  caption,
  captionHeight = 30,
  children,
  ...rest
}) => (
  <Root data-test-specifier={dataTestSpecifier}>
    {isLoading && <StyledAssetCardSkeleton />}
    {!isLoading && (
      <>
        <Content>
          {assetType === 'raw' && <AssetCardRenderer assetType="raw" />}
          {assetType !== 'raw' && (isError ? <AssetCardError /> : <AssetCardRenderer assetType={assetType} assetSrc={assetSrc} />)}
          <AssetCardData>
            {children && <Children {...rest}>{children}</Children>}
            {renderMetadata({ assetType, ...rest })}
          </AssetCardData>
        </Content>
        <Caption height={captionHeight} data-test-specifier={`caption-${dataTestSpecifier}`}>
          <Tooltip title={caption} dataTestSpecifier={`tooltip-${dataTestSpecifier}`}>
            <Tooltip.OverflowTrigger>{caption}</Tooltip.OverflowTrigger>
          </Tooltip>
        </Caption>
      </>
    )}
  </Root>
);

export { AssetCard, AssetCardData, Caption, StyledAssetCardSkeleton };
