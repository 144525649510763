import React, { FC, useMemo, useContext } from 'react';
import { IntlProvider, IntlConfig } from 'react-intl';
import { defaultLocale } from '../constants';
import { LocaleContext } from '../locale-context';
import { useTranslation, Translation, LoadMessages } from '../locale.hooks';

export type LanguageProviderProps = Partial<Omit<IntlProvider['props'], keyof Translation>> & {
  loadMessages: LoadMessages;
  translationOverrides?: {
    [key: string]: IntlConfig['messages'];
  };
};

/**
 * Provides translation messages as well as locale information to all Intl components and functions.
 */
export const LanguageProvider: FC<React.PropsWithChildren<LanguageProviderProps>> = ({ loadMessages, translationOverrides, ...props }) => {
  const desiredLocale = useContext(LocaleContext);
  const { messages, locale } = useTranslation(loadMessages);
  const finalMessages = useMemo(() => {
    // Handles correct format of locale, as well as legacy one
    const overrides = translationOverrides?.[desiredLocale] || translationOverrides?.[desiredLocale.replace('-', '_')];

    return Object.assign({}, messages, overrides);
  }, [desiredLocale, messages, translationOverrides]);

  return <IntlProvider defaultLocale={defaultLocale} locale={locale} messages={finalMessages} {...props} />;
};

export function withLanguageProvider<ComponentProps>(
  languageProviderProps: LanguageProviderProps,
  Component: React.FC<React.PropsWithChildren<ComponentProps>>
) {
  const wrappedComponent = (props: ComponentProps) => (
    <LanguageProvider {...languageProviderProps}>
      <Component {...props} />
    </LanguageProvider>
  );

  wrappedComponent.displayName = `withLanguageProvider(${Component.displayName || ''})`;

  return wrappedComponent;
}
