import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { isRawAsset } from '@cld/console-apps-types';

import { getAssetTrackingParams } from '../../services/tracking/tracking';
import { useCloudinaryTracker } from '../../common/hooks/useCloudinaryTracker';
import { Content } from './Content';
import { Header } from './Header';
import { Navigable } from './Navigable';

const Layout = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
`;

export const Preview = ({ canNavigateToNext, canNavigateToPrevious, onClose, onNext, onPrevious, previewedAsset, previewedCollection, onDownload }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        onPrevious();
      } else if (event.key === 'ArrowRight') {
        onNext();
      } else if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, onNext, onPrevious]);

  const cloudinaryTracker = useCloudinaryTracker();

  const trackAssetEvent = useCallback(
    (extraParams) =>
      cloudinaryTracker({
        ...getAssetTrackingParams({ asset: previewedAsset, collection: previewedCollection }),
        ...extraParams,
      }),
    [cloudinaryTracker, previewedAsset, previewedCollection]
  );

  const wasContentPlayedBefore = useRef(false);

  const previewedAssetPublicId = previewedAsset?.publicId;
  useEffect(() => {
    wasContentPlayedBefore.current = false;
  }, [previewedAssetPublicId]);

  const onContentPlay = useCallback(() => {
    if (wasContentPlayedBefore.current) {
      trackAssetEvent({ eventName: 'portal_asset_preview_resume_click' });
    } else {
      trackAssetEvent({ eventName: 'portal_asset_preview_play_click' });
    }
    wasContentPlayedBefore.current = true;
  }, [trackAssetEvent]);

  const onContentPause = useCallback(() => {
    trackAssetEvent({ eventName: 'portal_asset_preview_pause_click' });
  }, [trackAssetEvent]);

  if (!previewedAsset) {
    return null;
  }

  return (
    <Layout>
      <Header asset={previewedAsset} onClose={onClose} collection={previewedCollection} onDownload={onDownload} />

      <Navigable
        canNavigateToNext={canNavigateToNext}
        canNavigateToPrevious={canNavigateToPrevious}
        onNext={onNext}
        onPrevious={onPrevious}
        color={isRawAsset(previewedAsset) ? 'background' : 'contrastHigh'}
      >
        <Content asset={previewedAsset} onPlay={onContentPlay} onPause={onContentPause} data-test="content" />
      </Navigable>
    </Layout>
  );
};

Preview.propTypes = {
  canNavigateToNext: PropTypes.bool.isRequired,
  canNavigateToPrevious: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};
