import * as yup from '@cld/yup-extra';

import { AssetType } from '../AssetType';
import { SocialUploadType } from '../UploadType';
import { assetBaseSchema } from './AssetBase';

export const socialAssetSchema = assetBaseSchema
  .shape({
    uploadType: yup.mixed().oneOf(Object.values(SocialUploadType)).required(),
    type: yup.mixed().oneOf(Object.values(AssetType)).required(),
  })
  .required();

export type SocialAsset = yup.InferType<typeof socialAssetSchema>;
