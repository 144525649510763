import React, { FC, memo } from 'react';
import { NavigationContainer, NavigationMenuItem } from '@cld/navigation-menu';
import { StyledNavigationLinkContainer } from './Navigation.styles';
import { Tooltip } from '@clds/tooltip';

interface NavigationProps {
  onItemClick: (id: string) => void;
  items: Array<{
    id: string;
    value: string;
  }>;
  activeCollectionId?: string;
}

export const Navigation: FC<React.PropsWithChildren<NavigationProps>> = ({ items, activeCollectionId = undefined, onItemClick }) => (
  <NavigationContainer>
    {items.map(({ id, value }) => {
      return (
        <NavigationMenuItem
          key={id}
          data-test-specifier={`navigation-item-${value}`}
          isSelected={id === activeCollectionId}
          onClick={() => onItemClick(id)}
        >
          <StyledNavigationLinkContainer>
            <Tooltip maxWidth="md" placement="right" title={value}>
              <Tooltip.OverflowTrigger>{value}</Tooltip.OverflowTrigger>
            </Tooltip>
          </StyledNavigationLinkContainer>
        </NavigationMenuItem>
      );
    })}
  </NavigationContainer>
);

export default memo(Navigation);
