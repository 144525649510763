export const defaultLocale = 'en-US';

export const supportedLanguages: Record<string, string> = Object.freeze({
  'en-US': 'English',
  'de-DE': 'Deutsch',
  'es-ES': 'Español',
  'it-IT': 'Italiano',
  'fr-FR': 'Français',
  'nl-NL': 'Nederlands',
});
