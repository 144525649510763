import { createTheme, createVariant, globalTheme } from '@clds/component-theme';
import { DrawerThemeProps } from './types';

export const drawerTheme = createTheme(
  {
    backdrop: {
      background: {
        color: globalTheme.color.black,
      },
    },
    background: {
      color: globalTheme.palette.surface,
    },
    size: {
      sm: '250px',
      md: '330px',
      lg: '350px',
    },
  },
  'drawer'
);

export const drawerSizeVariant = createVariant((props: Required<Pick<DrawerThemeProps, 'size'>>) => drawerTheme.size[props.size]);
