import React, { createContext, memo, ReactNode, useCallback } from 'react';
import { getConfEnvName, ENVS } from '@cld/env-config';
import { sendCloudinaryAnalytics } from '../../services/dataService/dataService';
import { getDefaultCloudinaryAnalyticsParams } from './utils';

// eslint-disable-next-line @typescript-eslint/require-await
export const CloudinaryTrackerContext = createContext<typeof sendCloudinaryAnalytics | (() => Promise<boolean>)>(async () => {
  if (getConfEnvName() === ENVS.PRODUCTION) {
    // TODO: report error to an error reporting service
    return false;
  } else {
    throw new Error('No Provider for the CloudinaryTrackerContext');
  }
});

export const CloudinaryTrackerProvider = memo(({ children, ...defaultParams }: { children: ReactNode }) => {
  const tracker = useCallback(
    // TODO: Provide explicit types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (additionalParams: any) => sendCloudinaryAnalytics({ ...getDefaultCloudinaryAnalyticsParams(), ...defaultParams, ...additionalParams }),
    [defaultParams]
  );

  return <CloudinaryTrackerContext.Provider value={tracker}>{children}</CloudinaryTrackerContext.Provider>;
});
