import styled from 'styled-components';
import { FieldValidationState } from '@clds/common-definitions';
import { typography } from '@clds/typography';
import { EmojiPicker } from '@cld/emoji-picker';
import { wysiwygEditorTheme, borderColorVariant } from './theme';

export interface WrapperProps {
  validationState: FieldValidationState;
}

export const Wrapper = styled.div`
  border-radius: ${wysiwygEditorTheme.border.radius};
  background-color: ${wysiwygEditorTheme.background.color};
  max-width: 525px;
  min-width: 280px;
  width: 100%;

  ${EmojiPicker} {
    position: absolute;
    margin-left: ${wysiwygEditorTheme.emojiSection.spacing.left};
    z-index: 100;
  }
`;

// This empty styled span is ment to create a new "natural" stacking order,
// and prevent the usage of z-index.
export const EmojiSection = styled.span``;

export const EditorToolbar = styled.div`
  padding: ${wysiwygEditorTheme.toolbar.padding};
  background-color: ${wysiwygEditorTheme.toolbar.background.color};
  border: solid 1px ${wysiwygEditorTheme.border.none.color};
  border-top-right-radius: ${wysiwygEditorTheme.editableElement.border.radius};
  border-top-left-radius: ${wysiwygEditorTheme.editableElement.border.radius};
  border-bottom: none;
  display: flex;
`;

export const EditableElement = styled.div<WrapperProps>`
  padding: ${wysiwygEditorTheme.editableElement.padding.all};
  padding-bottom: ${wysiwygEditorTheme.editableElement.padding.bottom};
  border: solid 1px ${borderColorVariant((validationState) => validationState.color)};
  border-bottom-right-radius: ${wysiwygEditorTheme.editableElement.border.radius};
  border-bottom-left-radius: ${wysiwygEditorTheme.editableElement.border.radius};
  height: 189px;
  overflow: auto;
  ${typography.sm.regular}

  /* Makes the whole wysiwyg-editor editable area be focused */
  div[role='textbox'] {
    height: 100%;
  }

  * {
    margin-block-start: unset;
    margin-block-end: unset;
  }
`;
