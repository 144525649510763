import * as yup from '@cld/yup-extra';

import { AssetType } from '../AssetType';
import { NonSocialUploadType } from '../UploadType';
import { assetBaseSchema } from './AssetBase';

export const videoAssetSchema = assetBaseSchema
  .shape({
    type: yup.mixed().oneOf([AssetType.Video]).required(),
    uploadType: yup.mixed().oneOf(Object.values(NonSocialUploadType)).required(),
    duration: yup.number().nullable(),
    height: yup.number().required(),
    width: yup.number().required(),
  })
  .required();

export type VideoAsset = yup.InferType<typeof videoAssetSchema>;
