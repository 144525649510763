import * as yup from '@cld/yup-extra';
import { ResourceStatus, ResourceSubStatus } from '../ResourceStatuses';

export const assetBaseSchema = yup
  .object({
    publicId: yup.string().required(),
    displayName: yup.string().nullable(),
    externalId: yup.string().required(),
    filename: yup.string().required(),
    format: yup.string().required(),
    bytes: yup.number().required(),
    urls: yup
      .object({
        original: yup.string().required(),
        originalDownload: yup.string().notRequired(),
        preview: yup.string().required(),
        previewDownload: yup.string().optional(),
        thumbnail: yup.string().required(),
        thumbnailDownload: yup.string().optional(),
      })
      .required(),
    status: yup.mixed().oneOf(Object.values(ResourceStatus)).required(),
    subStatus: yup.mixed().oneOf([...Object.values(ResourceSubStatus), null]),
    tags: yup.array().of(yup.string().nullable()).notRequired(),
    metadata: yup.mixed(),
  })
  .required();

export type AssetBase = yup.InferType<typeof assetBaseSchema>;
