import { createTheme, globalTheme } from '@clds/component-theme';
// eslint-disable-next-line no-duplicate-imports
import type {} from '@clds/component-theme';

export const skeletonTheme = createTheme(
  {
    base: globalTheme.palette.background,
    lighten: -0.05, // number from -1 <-> 1
  },
  'skeleton'
);
