import { FormattedMessage, useIntl } from 'react-intl';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ArrowDropDown, FileDownload } from '@clds/icon';
import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';
import { usePhone } from '@clds/style-utils';
import { Tooltip } from '@clds/tooltip';
import { Button } from '@clds/button';
import { messages } from '../../i18n';
import { COLLECTION_QUERY_PARAM } from '../../constants/queryParams';
import { upsertQueryParam } from '../../common/helpers/urlHelpers';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${(p) => (p.open ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transform-origin: center;
  cursor: pointer;
`;

export const StyledAnchor = styled.a`
  font-weight: 500;
  font-size: ${globalTheme.font.size.md};
  color: ${globalTheme.palette.primary};
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledAnchorWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;
`;

export const Root = styled('div')`
  display: flex;
  flex-shrink: 0; // Fix Safari bug
  align-items: center;
  gap: ${globalTheme.spacing.xs};

  ${Button} {
    margin-left: ${globalTheme.spacing.xs};
  }

  &:hover {
    ${StyledAnchorWrapper} {
      opacity: 1;
    }
  }
`;

export const Title = styled.span`
  overflow: hidden;
  display: inline-flex;
  ${typography.md.highContrast};
`;

const Count = styled.span`
  flex-shrink: 0;
  ${typography.md.highContrast};
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledButton = styled(Button)`
  ${({ visible }) =>
    !visible &&
    css`
      visibility: hidden;
    `};
`;

export const CollectionHeaderItem = ({ title, collectionId, count, open, onDownload, onArrowClick, ...rest }) => {
  const rootRef = useRef();
  let isPhone = usePhone();
  const { formatMessage } = useIntl();
  const collectionDownloadMessage = formatMessage(messages.collectionDownload);

  const handleAnchorClick = (event) => {
    if (!rootRef.current) return;
    event.preventDefault();
    rootRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    upsertQueryParam(COLLECTION_QUERY_PARAM, collectionId);
  };

  const getCollectionUrl = () => {
    return `${location.origin}${location.pathname}?${COLLECTION_QUERY_PARAM}=${collectionId}`;
  };

  return (
    <Root ref={rootRef} open={open} {...rest} data-test="collection-header-item">
      <IconWrapper open={open} onClick={onArrowClick} data-test="collection-header-item-arrow">
        <ArrowDropDown size="sm" />
      </IconWrapper>
      <Title>
        <Tooltip maxWidth="lg" placement="bottomRight" title={title}>
          <Tooltip.OverflowTrigger>{title}</Tooltip.OverflowTrigger>
        </Tooltip>
      </Title>
      <Count>({count})</Count>
      <StyledAnchorWrapper>
        <CopyToClipboard text={getCollectionUrl()}>
          <StyledAnchor onClick={handleAnchorClick}>#</StyledAnchor>
        </CopyToClipboard>
      </StyledAnchorWrapper>
      {onDownload && (
        <>
          <Spacer />
          <StyledButton
            data-test="download-button"
            visible={open}
            onClick={onDownload}
            aria-label={collectionDownloadMessage}
            leftSlot={<FileDownload size="sm" ƒ />}
          >
            {!isPhone && <FormattedMessage {...messages.collectionDownload} />}
          </StyledButton>
        </>
      )}
    </Root>
  );
};

CollectionHeaderItem.propTypes = {
  title: PropTypes.node,
  count: PropTypes.number,
  onDownload: PropTypes.func,
  open: PropTypes.bool,
};

CollectionHeaderItem.defaultProps = {
  open: true,
};
