import { FieldValidationState } from '@clds/common-definitions';
import { createTheme, globalTheme, createVariant } from '@clds/component-theme';

export interface ThemeProps {
  validationState: FieldValidationState;
}

export const wysiwygEditorTheme = createTheme({
  background: {
    color: globalTheme.palette.surfaceAlt,
  },
  border: {
    radius: globalTheme.radius.lg,
    error: {
      color: globalTheme.palette.error,
    },
    none: {
      color: globalTheme.palette.secondaryAlt,
    },
    success: {
      color: globalTheme.palette.success,
    },
    warning: {
      color: globalTheme.palette.warning,
    },
  },
  toolbar: {
    padding: globalTheme.spacing.xxs,
    background: {
      color: globalTheme.palette.surface,
    },
  },
  editableElement: {
    padding: {
      all: globalTheme.spacing.xs,
      bottom: globalTheme.spacing.xxs,
    },
    border: {
      radius: globalTheme.radius.lg,
    },
  },
  emojiSection: {
    spacing: {
      left: globalTheme.spacing.xl,
    },
  },
});

export const borderColorVariant = createVariant((props: ThemeProps) => wysiwygEditorTheme.border[props.validationState]);
