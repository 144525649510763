import React, { FC, useContext, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePhone } from '@clds/style-utils';
import { Description } from '@cld/console-apps-types';
import { Tooltip } from '@clds/tooltip';
import { PortalLogo } from '../../services/dataService/PortalData';
import { DesktopLanguageSelector } from './LanguageSelector/DesktopLanguageSelector';
import { MobileLanguageSelector } from './LanguageSelector/MobileLanguageSelector';
import { ThemeContext, getThemeIcon } from '../appTheme.helpers';
import { messages } from '../../i18n';
import { Button } from '@clds/button';
import {
  Holder,
  LogoContainer,
  StyledDescription,
  StyledImg,
  StyledLanguageSelectorWrapper,
  StyledLogoTitleWrapper,
  StyledRightSlot,
  StyledTitle,
  StyledCollapsedHeader,
  StyledExpandedHeader,
  StyledHeaderTopSection,
  StyledExpandedTitle,
  CollapsedLogoContainer,
} from './Header.styles';

interface HeaderProps {
  title: string;
  description?: Description;
  logo?: PortalLogo | null; // TODO remove ? once BE is merged https://cloudinary.atlassian.net/browse/DAM-8200
  contentRef?: React.RefObject<HTMLElement>;
}

export const Header: FC<React.PropsWithChildren<HeaderProps>> = ({ description, title, logo, contentRef }) => {
  const isPhone = usePhone();
  const { formatMessage } = useIntl();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { themeName, toggleTheme } = useContext(ThemeContext);
  const ThemeIcon = getThemeIcon(themeName);
  const displayDescription = !!(title && description); // Prevents empty strings

  useEffect(() => {
    const contentEl = contentRef?.current;
    if (!contentEl) return;

    const handleScroll = () => {
      const scrollTop = contentEl.scrollTop;
      setIsCollapsed(scrollTop > 100);
    };
    contentEl.addEventListener('scroll', handleScroll);
    return () => {
      contentEl.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderCollapsedHeader = () => (
    <StyledCollapsedHeader isCollapsed={isCollapsed}>
      <StyledLogoTitleWrapper>
        {logo && (
          <CollapsedLogoContainer logo={logo}>
            <StyledImg src={logo.logoDeliveryUrl} alt={'logo'} />
          </CollapsedLogoContainer>
        )}
        <StyledTitle>
          <Tooltip maxWidth="lg" placement="bottomRight" title={title}>
            <Tooltip.OverflowTrigger>{title}</Tooltip.OverflowTrigger>
          </Tooltip>
        </StyledTitle>
      </StyledLogoTitleWrapper>
    </StyledCollapsedHeader>
  );

  const renderExpandedHeader = () => (
    <StyledExpandedHeader isCollapsed={isCollapsed}>
      {logo && (
        <LogoContainer logo={logo}>
          <StyledImg src={logo.logoDeliveryUrl} alt={'logo'} />
        </LogoContainer>
      )}
      <StyledExpandedTitle logo={logo}>
        <Tooltip maxWidth="lg" placement="bottomRight" title={title}>
          <Tooltip.OverflowTrigger>{title}</Tooltip.OverflowTrigger>
        </Tooltip>
      </StyledExpandedTitle>
      {displayDescription && <StyledDescription> {description} </StyledDescription>}
    </StyledExpandedHeader>
  );

  return (
    <Holder data-test="portal-header" isCollapsed={isCollapsed}>
      <StyledHeaderTopSection isCollapsed={isCollapsed}>
        {renderCollapsedHeader()}
        <StyledRightSlot>
          <Tooltip title={formatMessage(messages[`${themeName}Theme`])}>
            <Button onClick={toggleTheme} variant="secondary" size="sm" type="button" tone="plain" leftSlot={<ThemeIcon />} />
          </Tooltip>
          <StyledLanguageSelectorWrapper>{isPhone ? <MobileLanguageSelector /> : <DesktopLanguageSelector />}</StyledLanguageSelectorWrapper>
        </StyledRightSlot>
      </StyledHeaderTopSection>
      {renderExpandedHeader()}
    </Holder>
  );
};
