import { ValidateOptions } from '@cld/yup-extra';

import { AssetType } from '../AssetType';
import { SocialUploadType, UploadType } from '../UploadType';
import { AudioAsset, audioAssetSchema } from './AudioAsset';
import { ImageAsset, imageAssetSchema } from './ImageAsset';
import { SocialAsset, socialAssetSchema } from './SocialAsset';
import { VideoAsset, videoAssetSchema } from './VideoAsset';
import { RawAsset, rawAssetSchema } from './RawAsset';

export type Asset = AudioAsset | ImageAsset | SocialAsset | VideoAsset | RawAsset;

export const isSocialAsset = (asset: Asset): asset is SocialAsset => {
  return isSocialUploadType(asset?.uploadType);
};

export const isAudioAsset = (asset: Asset): asset is AudioAsset => {
  return !isSocialAsset(asset) && asset?.type === AssetType.Audio;
};

export const isImageAsset = (asset: Asset): asset is ImageAsset => {
  return !isSocialAsset(asset) && asset?.type === AssetType.Image;
};

export const isVideoAsset = (asset: Asset): asset is VideoAsset => {
  return !isSocialAsset(asset) && asset?.type === AssetType.Video;
};

export const isRawAsset = (asset: Asset): asset is RawAsset => {
  return !isSocialAsset(asset) && asset?.type === AssetType.Raw;
};

const assetSchemas = {
  [AssetType.Audio]: audioAssetSchema,
  [AssetType.Image]: imageAssetSchema,
  [AssetType.Video]: videoAssetSchema,
  [AssetType.Raw]: rawAssetSchema,
};

export const validateAsset = (uploadType: UploadType, assetType: AssetType, asset: unknown, validateOptions?: ValidateOptions): Asset => {
  const effectiveValidateOptions = {
    abortEarly: false,
    stripUnknown: true,
    ...validateOptions,
  };

  if (isSocialUploadType(uploadType)) {
    return socialAssetSchema.validateSync(asset, effectiveValidateOptions);
  }

  return assetSchemas[assetType].validateSync(asset, effectiveValidateOptions);
};

const isSocialUploadType = (uploadType: UploadType): uploadType is SocialUploadType => {
  return Object.values(SocialUploadType).includes(uploadType as SocialUploadType);
};
