import React, { FC } from 'react';
import styled, { css, keyframes, StyledComponent } from 'styled-components';
import { combineValues, globalTheme } from '@clds/component-theme';
import { Icon } from '@cld/icon';
import { withCheckerboardBackground } from '@cld/with-checkerboard-background';
import { AssetTypes } from '../Asset.types';
import { t } from '../AssetCardTheme';

export interface ImageProps {
  src: string;
  className?: string;
}

type AssetCardRendererBaseProps = Pick<AssetTypes, 'assetType'> & Partial<Pick<AssetTypes, 'assetSrc'>>;

const BaseImage = styled.img.attrs(() => ({
  alt: 'asset',
}))``;
const CheckeredImage = withCheckerboardBackground(BaseImage);
const Image: FC<ImageProps & { type: AssetTypes['assetType'] }> = ({ src, type }) =>
  ['image', 'video'].includes(type) ? <CheckeredImage src={src} /> : <BaseImage src={src} />;

const AssetCardRendererBase: FC<AssetCardRendererBaseProps> = ({ assetSrc, assetType, ...rest }) => (
  <div {...rest}>
    {assetSrc && <Image src={assetSrc} type={assetType} />}
    {assetType === 'raw' && <Icon name="file" />}
    {assetType === 'video' && <Icon name="video" />}
  </div>
);

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;

/**
 * Internal component of the AssetCard. Renders the img tag with image, audio / video thumbnail, or raw file icon.
 */
export const AssetCardRenderer: StyledComponent<typeof AssetCardRendererBase, AssetCardRendererBaseProps> = styled(AssetCardRendererBase).attrs({
  'data-test': 'asset-card-renderer',
})`
  position: relative;
  border-radius: ${globalTheme.radius.lg};
  background-color: ${({ assetType }) => (assetType === 'audio' ? t.background.color.audio : t.background.color.default)};
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: ${globalTheme.radius.lg};
    animation: ${fade} 300ms linear;
  }

  ${Icon} {
    position: absolute;
    width: ${t.icon.size}px;
    height: ${t.icon.size}px;
    top: ${combineValues(t.icon.size, (size) => `calc(50% - ${size / 2}px)`)};
    left: ${combineValues(t.icon.size, (size) => `calc(50% - ${size / 2}px)`)};

    ${({ assetType }) =>
      assetType === 'raw' &&
      css`
        color: ${t.icon.color.raw};
      `}

    ${({ assetType }) =>
      assetType === 'video' &&
      css`
        color: ${t.icon.color.video};
        filter: drop-shadow(0 0 5px ${t.icon.color.shadow});
      `}
  }
`;
