import formatDuration from 'format-duration';
import React, { memo, FC } from 'react';
import styled from 'styled-components';
import { globalTheme, GlobalTheme } from '@clds/component-theme';
import { asStyledComponent } from '@clds/component-enhancer';
import AssetDimensions from '@cld/asset-dimensions';
import AssetSize from '@cld/asset-size';
import AssetType from '@cld/asset-type';

const Wrapper = styled.div<{ color: keyof GlobalTheme['color'] }>`
  display: flex;

  color: ${({ color }) => globalTheme.color[color]};
  font-size: ${globalTheme.font.size.xxs};

  ${AssetType} {
    flex-shrink: 0;

    display: flex;
    align-items: baseline;

    span {
      line-height: 0.75em;
      height: 0.75em;
    }
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const Segments = styled.div`
  display: flex;
  > * :not(:first-child) {
    & :before {
      content: '|';
      margin-left: ${globalTheme.spacing.xxs};
      margin-right: ${globalTheme.spacing.xxs};
    }
  }
`;

const Duration = ({ duration }: { duration: number }) => <div data-test="duration">{formatDuration(duration * 1000)}</div>;
const isValidDimension = (height: number, width: number) => height > 0 && width > 0;
const isValidDuration = (duration: number) => duration > 0;

export interface AssetMetadataHorizontalProps {
  type: 'audio' | 'image' | 'video' | 'raw';
  format: string;
  bytes: number;
  height: number;
  width: number;
  duration: number;
  color: keyof GlobalTheme['color'];
}

const AssetMetadataHorizontal: FC<React.PropsWithChildren<AssetMetadataHorizontalProps>> = ({
  type,
  format,
  bytes,
  height,
  width,
  duration,
  color,
  ...rest
}) => {
  return (
    <Wrapper data-test="asset-metadata" color={color} {...rest}>
      <AssetType type={type} format={format} color={color} />
      <Spacer />
      <Segments>
        <AssetSize key="assetSize" bytes={bytes} />
        {isValidDimension(height, width) && <AssetDimensions key="assetDimensions" height={height} width={width} />}
        {isValidDuration(duration) && <Duration key="assetDuration" duration={duration} />}
      </Segments>
    </Wrapper>
  );
};

export default asStyledComponent(memo(AssetMetadataHorizontal));
