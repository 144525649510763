import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Typography } from '@clds/typography';
import { asStyledComponent } from '@clds/component-enhancer';

export interface CommonEmptyStateProps {
  subtitle?: ReactNode;
  title?: string;
}

export interface EmptyStateProps extends CommonEmptyStateProps {
  /** Children to render as action */
  children?: ReactNode | Array<ReactNode>;
  /** Image height */
  imageHeight?: number;
  /** Image source */
  imageSrc: string;
  /** Image width */
  imageWidth?: number;
  /** Temporary TS fix */
  css?: Record<string, string>;
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Image = styled.img`
  margin-bottom: ${globalTheme.spacing.xs};
  max-width: 100%;
`;

const Title = styled(Typography)`
  margin-bottom: ${globalTheme.spacing.xs};
`;

const Subtitle = styled(Typography)`
  margin-bottom: ${globalTheme.spacing.xs};
`;

export const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(({ children, imageSrc, imageHeight, imageWidth, subtitle, title, ...rest }, ref) => (
  <Wrapper ref={ref} {...rest}>
    <Image alt={title} height={imageHeight} width={imageWidth} src={imageSrc} />
    {title && (
      <Title size="xl" type="regular" data-test="empty-state-title">
        {title}
      </Title>
    )}
    {subtitle && (
      <Subtitle size="sm" type="regular" data-test="empty-state-subtitle">
        {subtitle}
      </Subtitle>
    )}
    {children}
  </Wrapper>
));

export default asStyledComponent(EmptyState);
