import { useEffect, useState } from 'react';
const millisecondsInMinute = 60 * 1000;

export const useExpirableSession = (expiresInMinutes: number) => {
  const [hasSessionExpired, setHasSessionExpired] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasSessionExpired(true);
    }, expiresInMinutes * millisecondsInMinute);
    return () => clearTimeout(timeout);
  }, [expiresInMinutes]);
  return hasSessionExpired;
};
