//allow using this module also when some dependencies/variables does not exist
let getEnvName;
try {
  getEnvName = require('@cld/env-config').getConfEnvName;
} catch (e) {
  getEnvName = () => '';
}

let version;
try {
  // eslint-disable-next-line no-undef
  version = VERSION;
} catch (e) {
  version = '';
}

const NO_INDICATION_ENV = ['production', 'console'];

const isNotProduction = (envName) => !NO_INDICATION_ENV.some((e) => e === envName.toLowerCase());

const handleIndication = (envName, pkgVer) => {
  if (envName && isNotProduction(envName)) {
    const elem = document.createElement('div');
    elem.setAttribute('data-test', 'env-info-label');
    elem.innerHTML = `<b>${envName}</b>${pkgVer ? ' ' : ''}${pkgVer}`;
    const styles = {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 100000,
      lineHeight: '1em',
      padding: '0 5px',
      fontSize: '10px',
      backgroundColor: '#cfd8dc',
      textTransform: 'uppercase',
      borderBottomRightRadius: '5px',
      borderBottom: '1px solid #b0bec5',
      borderRight: '1px solid #b0bec5',
      color: '#000000cc',
    };
    Object.assign(elem.style, styles);
    document.body.appendChild(elem);
  }
};

module.exports = () => document.addEventListener('DOMContentLoaded', () => handleIndication(getEnvName(), version));
