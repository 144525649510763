import React, { useState } from 'react';

const withRenderOnHover =
  (Component) =>
  ({ children, onFocus, onMouseOver, ...props }) => {
    const [show, setShow] = useState(false);

    const handleFocus = (event) => {
      event.persist();
      setShow(true);
      if (typeof onFocus === 'function') {
        onFocus(event);
      }
    };

    const handleOnMouseOver = (event) => {
      event.persist();
      setShow(true);
      if (typeof onMouseOver === 'function') {
        onMouseOver(event);
      }
    };

    return (
      <Component onFocus={handleFocus} onMouseOver={handleOnMouseOver} {...props}>
        {show && children}
      </Component>
    );
  };

export default withRenderOnHover;
