/* istanbul ignore file */
import { some, uniq, filter } from 'lodash';
import { formatBytes } from '@cld/format-bytes';
import { Asset, AssetType, isImageAsset, isVideoAsset } from '@cld/console-apps-types';
import { SharedPreset } from '../../types';

export const getCollectionCalculatedSize = (collection: Array<Asset>) => {
  let collectionSize = 0;
  collection.forEach((asset) => (collectionSize += asset?.bytes));
  const calculatedCollectionSize = formatBytes(collectionSize || 0, 2);
  return calculatedCollectionSize;
};

export const getAssetCalculatedSize = (asset: Asset) => formatBytes(asset?.bytes || 0, 2);

export const getAssetFormat = (asset: Asset) => (asset?.format || '').toUpperCase();

export const getAssetTypes = (assets: Asset[]) => {
  const assetsTypes: Partial<Record<AssetType, boolean>> = {};
  assets.forEach((asset) => {
    assetsTypes[asset.type] = true;
  });
  return assetsTypes;
};

export const getAssetDimensions = (asset: Asset) => (isImageAsset(asset) ? `${asset?.width || ''} x ${asset?.height || ''}` : '');

export const getFilteredPresets = (assets: Asset[], presets: SharedPreset[]) => {
  const existingAssetTypes: string[] = uniq(assets?.map((asset: Asset) => asset?.type));
  const filteredPresets = filter(presets, ({ presetTypes }) => some(presetTypes, (presetType) => existingAssetTypes.includes(presetType)));
  return filteredPresets;
};

export const getPresetAssets = (assets: Asset[]) => some(assets, (asset) => isImageAsset(asset) || isVideoAsset(asset));

export const hasRawAssets = (assets: Asset[]) => filter(assets, { type: 'raw' });

export const getAssetDisplayName = (asset: Asset) => `${asset?.displayName || asset?.filename}.${asset?.format}`;
