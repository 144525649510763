import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';
import Button from '@cld/button';
import { Icon } from '@cld/icon';
// import { Restore } from '@clds/icon';
import { messages } from '../../i18n';
import stopwatch from './stopwatch.svg';

const Wrapper = styled.div`
  background-color: ${globalTheme.palette.surface};
  border-radius: 10px;
`;

const Header = styled.div`
  width: 100%;
  padding: ${globalTheme.spacing.md};
  background-color: ${globalTheme.palette.background};
  border-radius: 10px 10px 0 0;
  ${typography.lg.regular};
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  color: ${globalTheme.palette.contrastLow};
  background-color: ${globalTheme.palette.surface};
  padding: ${globalTheme.spacing.md};
  display: flex;
  align-items: center;
`;

const Graphic = styled.img`
  margin-right: ${globalTheme.spacing.xs};
`;

const Actions = styled.div`
  width: 100%;
  padding: ${globalTheme.spacing.md};
  background-color: ${globalTheme.palette.surface};
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${globalTheme.palette.secondary};
`;

// const RestoreIconWrapper = styled.div`
//   padding-right: 10px;
//   svg {
//     width: 45px;
//     height: 45px;
//   }
// `;

export const RefreshModal = () => {
  const reload = () => window.location.reload();
  return (
    <Wrapper>
      <Header>
        <FormattedMessage {...messages.portalRefreshModalHeader} />
      </Header>
      <Content>
        {/* <RestoreIconWrapper>
          <Restore />
        </RestoreIconWrapper> */}
        <Graphic src={stopwatch} alt="stopwatch" />
        <FormattedMessage {...messages.portalRefreshModalMessage} />
      </Content>
      <Actions>
        <Button onClick={reload}>
          <Icon name="reload" />
          <FormattedMessage {...messages.portalRefreshModalButton} />
        </Button>
      </Actions>
    </Wrapper>
  );
};
