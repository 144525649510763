import * as yup from '@cld/yup-extra';

export const richTextSchema = yup
  .array()
  .of(
    yup
      .object({
        type: yup.string().required(),
        children: yup
          .array()
          .of(
            yup
              .object({
                text: yup.string().optional(),
                bold: yup.bool().optional(),
                italic: yup.bool().optional(),
                underline: yup.bool().optional(),
                strike: yup.bool().optional(),
                unified: yup.string().optional(),
              })
              .required()
          )
          .required(),
      })
      .required()
  )
  .nullable();
