import * as yup from '@cld/yup-extra';

import { AssetType } from '../AssetType';
import { NonSocialUploadType } from '../UploadType';
import { assetBaseSchema } from './AssetBase';

export const imageAssetSchema = assetBaseSchema
  .shape({
    type: yup.mixed().oneOf([AssetType.Image]).required(),
    uploadType: yup.mixed().oneOf(Object.values(NonSocialUploadType)).required(),
    // 3D images arrive without dimensions, therefore we accep null values.
    height: yup.number().nullable(),
    width: yup.number().nullable(),
  })
  .required();

export type ImageAsset = yup.InferType<typeof imageAssetSchema>;
