import { useMemo } from 'react';

export const useDropdownRegistry = () =>
  useMemo(() => {
    const registry = new Set<string>();
    const register = (id: string) => registry.add(id);
    const unregister = (id: string) => registry.delete(id);

    return {
      context: { registerNonClosableMenuItem: register, unregisterNonClosableMenuItem: unregister },
      isClosable: (id: string) => !registry.has(id),
    };
  }, []);
