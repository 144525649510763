import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assetDownload: {
    id: 'asset.download',
    defaultMessage: 'Download',
  },
  assetDownloadError: {
    id: 'asset.download.error',
    defaultMessage: 'Asset download failed.',
  },
  collectionDownload: {
    id: 'collection.download',
    defaultMessage: 'Download Collection',
  },
  menu: {
    id: 'menu',
    defaultMessage: 'More options',
  },
  portalNotFound: {
    id: 'portal.notFound',
    defaultMessage: 'Portal not found',
  },
  portalRefreshModalHeader: {
    id: 'portal.refreshModal.header',
    defaultMessage: 'Session Expired',
  },
  portalRefreshModalButton: {
    id: 'portal.refreshModal.button',
    defaultMessage: 'Refresh',
  },
  portalRefreshModalMessage: {
    id: 'portal.refreshModal.message',
    defaultMessage: "You've been here for a while. We suggest you refresh the page to make sure you're looking at the latest content.",
  },
  collectionNotFound: {
    id: 'collection.notFound',
    defaultMessage: 'Collection not found',
  },
  collectionEmpty: {
    id: 'collection.empty',
    defaultMessage: 'This collection is currently empty',
  },
  collectionAssetsCount: {
    id: 'collection.assetsCount',
    defaultMessage: '{count, plural, one {# asset} other {# assets}}',
  },
  original: {
    id: 'asset.original',
    defaultMessage: 'Original',
  },
  presetsDownload: {
    id: 'asset.downloadPresets',
    defaultMessage: 'Download a modified version of the file:',
  },
  presetsDownloadError: {
    id: 'asset.downloadPresets.error',
    defaultMessage: 'Download Presets Failed',
  },
  downloadSinglePresetsModalHeader: {
    id: 'asset.downloadSinglePresetsModalHeader',
    defaultMessage: 'Download Media File',
  },
  downloadCollectionPresetsModalHeader: {
    id: 'asset.downloadCollectionPresetsModalHeader',
    defaultMessage: 'Download Collection',
  },
  metadataCreatedBy: {
    id: 'metadata.createdBy',
    defaultMessage: 'Created by:',
  },
  metadataLastUpdated: {
    id: 'metadata.lastUpdated',
    defaultMessage: 'Last updated:',
  },
  refreshModalHeader: {
    id: 'refreshModal.header',
    defaultMessage: 'Session Expired',
  },
  refreshModalButton: {
    id: 'refreshModal.button',
    defaultMessage: 'Refresh',
  },
  refreshModalMessage: {
    id: 'refreshModal.message',
    defaultMessage: "You've been here for a while. We suggest you refresh the page to make sure you're looking at the latest content.",
  },
  lightTheme: {
    id: 'collection.theme.light',
    defaultMessage: 'Light',
  },
  darkTheme: {
    id: 'collection.theme.dark',
    defaultMessage: 'Dark',
  },
  systemTheme: {
    id: 'collection.theme.system',
    defaultMessage: 'System',
  },
});
