// istanbul ignore file
import { useIntl } from 'react-intl';
import Progress from '@clds/progress';
import { useFavicon } from 'react-use';
import { NotFound } from '@cld/empty-state';
import GlobalStyles from '@cld/global-style';
import { ThemeProvider } from 'styled-components';
import React, { useState, useEffect, useContext } from 'react';
import { CLOUDINARY_FAVICON } from '@cld/use-cloudinary-favicon';
import { useTitleFromPromise } from '@cld/console-apps-services';
import { useUpdatedDocumentLang, LocaleContext } from '@cld/intl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { messages } from '../i18n';
import { useData } from './data/useData';
import { useTheme } from './appTheme.hooks';
import { ProgressWrapper } from './App.styles';
import { ThemeContext } from './appTheme.helpers';
import { DataProvider } from './data/DataProvider';
import { BrandPortalContent } from './BrandPortalContent';
import { applyFaviconTransformation } from './App.helpers';
import { TranslationProvider } from './TranslationProvider';
import { SessionTimeout } from './SessionTimeout/SessionTimeout';
import { CollectionStateProvider } from './CollectionStateProvider';
import { useSupportedLanguages } from './Header/LanguageSelector/useSupportedLanguages';
import { CloudinaryTrackerProvider } from '../components/CloudinaryTrackerProvider/CloudinaryTrackerProvider';

// Create a client
const queryClient = new QueryClient();

const BrandPortal = () => {
  const { formatMessage } = useIntl();
  const notFoundMessage = formatMessage(messages.portalNotFound);
  const [favicon, setFavicon] = useState(null);

  const { info } = useData();

  useTitleFromPromise(info, (info) => info.name, notFoundMessage);
  useUpdatedDocumentLang();

  useFavicon(favicon);
  useEffect(() => {
    if (!info.isLoading) {
      const squareLogo = applyFaviconTransformation(info.data?.logo?.logoDeliveryUrl);
      setFavicon(squareLogo || CLOUDINARY_FAVICON);
    }
  }, [info.data, info.isLoading, info]);

  const locale = useContext(LocaleContext);
  const { languages } = useSupportedLanguages();
  const currentLanguage = languages.find((language) => language.languageKey === locale)?.languageLabel;

  if (info.isLoading) {
    return (
      <ProgressWrapper>
        <Progress.Circular variant="primary" size="xl" />
      </ProgressWrapper>
    );
  }

  if (info.error) {
    return <NotFound title={notFoundMessage} />;
  }

  const {
    name,
    description,
    collectionsInfo,
    sharedPresets,
    publicLinkId,
    logo,
    preferences: { preventDownloadOriginal },
  } = info.data;

  const notEmptyCollections = collectionsInfo ? collectionsInfo.filter((collection) => collection.resourceCount > 0) : [];

  return (
    <QueryClientProvider client={queryClient}>
      <CloudinaryTrackerProvider language={currentLanguage} title={name}>
        <BrandPortalContent
          portalName={name}
          portalDescription={description}
          portalLogo={logo}
          collectionsInfo={notEmptyCollections}
          downloadPresets={sharedPresets}
          publicLinkId={publicLinkId}
          preventDownloadOriginal={preventDownloadOriginal}
        />
      </CloudinaryTrackerProvider>
    </QueryClientProvider>
  );
};

const App = () => {
  const { themeName, theme, toggleTheme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ themeName, toggleTheme }}>
        <DataProvider>
          <CollectionStateProvider>
            <GlobalStyles />
            <TranslationProvider>
              <SessionTimeout>
                <BrandPortal />
              </SessionTimeout>
            </TranslationProvider>
          </CollectionStateProvider>
        </DataProvider>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export default App;
