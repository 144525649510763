// istanbul ignore file
import React, { FC, ReactNode, useMemo } from 'react';
import { usePromiseState } from '@cld/use-promise-state';
import { getInfo } from '../../services/dataService/dataService';
import { DataContext } from './DataContext';

export interface DataProviderProps {
  children?: ReactNode;
}

export const DataProvider: FC<React.PropsWithChildren<DataProviderProps>> = ({ children }) => {
  const getInfoPromise = useMemo(() => getInfo(), []);
  const getInfoState = usePromiseState(getInfoPromise);

  return (
    <DataContext.Provider
      // eslint-disable-next-line
      // @ts-ignore
      value={{
        info: getInfoState,
        expiresIn: 60, // minutes
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
