import React, { createContext, ReactNode, useContext, useMemo } from 'react';

export interface NavigationContextType {
  isDisabled: boolean;
}

export const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('You need to wrap your component with NavigationMenuProvider');
  }

  return context;
};

export const NavigationContextProvider = ({ isDisabled = false, children }: NavigationContextType & { children?: ReactNode }) => {
  const memoizedContextValue = useMemo(
    () => ({
      isDisabled,
    }),
    [isDisabled]
  );
  return <NavigationContext.Provider value={memoizedContextValue}>{children}</NavigationContext.Provider>;
};
