export enum ResourceStatus {
  Active = 'active',
  NotFound = 'not_found',
  Pending = 'pending',
  Tombstone = 'tombstone',
  Live = 'live',
}

export enum ResourceSubStatus {
  Deleted = 'deleted',
  Rejected = 'rejected',
  Placeholder = 'placeholder',
}
