import * as yup from '@cld/yup-extra';
import { richTextSchema } from '@cld/console-apps-types';

export const portalInfoSchema = yup
  .object({
    name: yup.string().required(),
    description: richTextSchema,
    logo: yup
      .object({
        logoDeliveryUrl: yup.string().url().required(),
        alignment: yup.string().oneOf(['center', 'left', 'right']).required(),
        size: yup.string().oneOf(['small', 'medium', 'large']).required(),
      })
      .camelCase()
      .nullable()
      .default(null),
    flags: yup
      .object({
        localizationEnabled: yup.boolean().optional(),
      })
      .camelCase()
      .optional(),
    collectionsInfo: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            id: yup.string().required(),
            name: yup.string().required(),
            resourceCount: yup.number().required(),
          })
          .camelCase()
          .required()
      )
      .optional(),
    sharedPresets: yup.array(
      yup
        .object({
          name: yup.string().required(),
          presetTypes: yup.array().of(yup.string().required()).required(),
          transformation: yup.string().required(),
        })
        .camelCase()
    ),
    publicLinkId: yup.string().required(),
    preferences: yup
      .object({
        locale: yup.string().nullable().optional(),
        preventDownloadOriginal: yup.boolean().optional(),
      })
      .camelCase()
      .optional(),
  })
  .from('title', 'name')
  .from('portal_description', 'description')
  .camelCase()
  .required();

export type PortalInfoDto = yup.InferType<typeof portalInfoSchema>;
