import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { media } from '@clds/style-utils';
import { Button } from '@cld/button';
import { HeaderHeight } from './Header';

const SideButton = styled.div`
  position: absolute;
  top: 50%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transform: translateY(-50%);
  z-index: 2;
  &,
  * {
    pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ color }) => globalTheme.palette[color]};
  position: relative;
  @media ${`${media.desktop({ minOnly: true })}`} {
    ${SideButton} {
      display: none;
    }

    &:hover {
      ${SideButton} {
        display: block;
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(props) => (props.isPhone ? HeaderHeight.phone : HeaderHeight.desktop)}px);
`;

export const StyledNextButton = styled(SideButton)`
  right: 0;
`;

export const StyledPrevButton = styled(SideButton)`
  left: 0;
`;

export const StyledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  // Override monorepo style since the button background is changed to secondary
  &:focus {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
`;
