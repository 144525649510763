import styled from 'styled-components';
// TDO: Move these to shared packages
import { DownloadPresetsModal } from '../DownloadPresetsModal/DownloadPresetsModal';
import { globalTheme } from '@clds/component-theme';

export const ProgressWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledDownloadPresetsModal = styled(DownloadPresetsModal)`
  position: relative;
  z-index: 300;
`;

export const StyledCollectionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Root = styled.div.attrs({ 'data-test': 'portal-root' })`
  background-color: ${globalTheme.palette.surface};
`;

export const StyledProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${globalTheme.spacing.xxl};
  margin: ${globalTheme.spacing.sm};
`;
