import { useLayoutEffect } from 'react';
import { Unselect } from '@clds/compound-components';

export const useResetSelectionOnOpen = (opened: boolean, selectedId: string | Unselect, setFocusedId: (value: string | Unselect) => void) => {
  useLayoutEffect(() => {
    if (opened) {
      setFocusedId(selectedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]); // we want to reset the focus after open
};
