import { createContext } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import light, { dark } from '@clds/themes';
import { generateTheme } from '@clds/theme-helper';
import { Moon, Sun, Theme } from './icons';

export enum THEME {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system',
}

export type ThemeType = THEME.LIGHT | THEME.DARK | THEME.SYSTEM;
export interface ThemeContextType {
  themeName: ThemeType;
  toggleTheme: () => void;
}

export const getNextTheme = (themeName: ThemeType) => {
  const themes = [THEME.LIGHT, THEME.DARK, THEME.SYSTEM];
  const currentIndex = themes.indexOf(themeName);
  const nextIndex = (currentIndex + 1) % themes.length;
  return themes[nextIndex];
};

export const getThemeIcon = (themeName: ThemeType) => {
  switch (themeName) {
    case THEME.LIGHT:
      return Sun;
    case THEME.DARK:
      return Moon;
    case THEME.SYSTEM:
      return Theme;
  }
};

export const ThemeContext = createContext<ThemeContextType>({ themeName: THEME.LIGHT, toggleTheme: () => console.log('bla') });

export const appThemeGen = (appThemeStored: ThemeType | undefined) => {
  const theme = appThemeStored === THEME.DARK ? dark : light;
  return generateTheme(theme);
};
