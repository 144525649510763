import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { forceCheck } from 'react-lazyload';
import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';
import { media } from '@clds/style-utils';
import { useIsCollectionOpen } from '../../common/hooks/useIsCollectionOpen';
import { useSetCollectionOpen } from '../../common/hooks/useSetCollectionOpen';
import { useCloudinaryTracker } from '../../common/hooks/useCloudinaryTracker';
import { getAssetTrackingParams } from '../../services/tracking/tracking';
import { CollectionHeaderItem } from './CollectionHeaderItem';
import CollectionGridItem from './CollectionGridItem';
import { AssetGrid } from './index';

export const StyledAssetGrid = styled(AssetGrid)`
  padding: ${globalTheme.spacing.lg};

  ${(p) =>
    !p.open &&
    css`
      display: none;
    `}
`;

const StyledDescription = styled.div`
  ${typography.sm.highContrast};
  padding: 0 30% 0 ${globalTheme.spacing.lg};
  font-weight: initial;

  ${(p) =>
    !p.open &&
    css`
      display: none;
    `}
`;

export const StyledCollectionHeaderItem = styled(CollectionHeaderItem)`
  padding: ${globalTheme.spacing.lg} ${globalTheme.spacing.lg} 0 ${globalTheme.spacing.lg};

  @media ${media.phone()} {
    scroll-margin-top: 3rem; // For the sticky nav bar to not override the collection header during navigation.
  }
`;

export const CollectionGridPure = ({ collection, open, onArrowClick, setPreviewedAssetIndex, getOnAssetDownload, onCollectionDownload }) => {
  const cloudinaryTracker = useCloudinaryTracker();

  useEffect(() => {
    // hint to react-lazyload that images might appear in viewport
    forceCheck();
  }, [open]);

  return (
    <>
      <StyledCollectionHeaderItem
        open={open}
        onArrowClick={onArrowClick}
        title={collection.name}
        collectionId={collection.id}
        description={collection.description}
        count={collection.assets.length}
        onDownload={onCollectionDownload}
        data-test="collection-header"
      />
      <StyledDescription open={open}>{collection.description}</StyledDescription>
      <StyledAssetGrid data-test-specifier={`collection-grid-${collection.name}`} data-test="collection-grid" open={open}>
        {collection.assets.map((asset, index) => {
          const trackAssetEvent = (extraParams) =>
            cloudinaryTracker({
              ...getAssetTrackingParams({ asset, collection }),
              ...extraParams,
            });

          const handleClick = () => {
            setPreviewedAssetIndex(index);
            trackAssetEvent({
              eventName: 'portal_asset_click',
            });
          };

          const onAssetDownload = getOnAssetDownload(asset);
          const onDownload = onAssetDownload
            ? () => {
                onAssetDownload(asset, collection);
              }
            : undefined;

          const onMenuClick = (event) => {
            // stop the asset from getting previewed
            event.stopPropagation();
            trackAssetEvent({
              eventName: 'portal_asset_menu_click',
            });
          };

          return (
            <CollectionGridItem
              data-test-specifier={`${index}-${asset.displayName || asset.filename}`}
              index={index}
              key={asset.type + '/' + asset.publicId}
              asset={asset}
              onClick={handleClick}
              onDownload={onDownload}
              onMenuClick={onMenuClick}
            />
          );
        })}
      </StyledAssetGrid>
    </>
  );
};

CollectionGridPure.propTypes = {
  open: PropTypes.bool,
  onHeaderClick: PropTypes.func,
  setPreviewedAssetIndex: PropTypes.func.isRequired,
  onCollectionDownload: PropTypes.func,
};

export const CollectionGrid = memo(({ collection, ...props }) => {
  const cloudinaryTracker = useCloudinaryTracker();

  const { id } = collection;
  const isOpen = useIsCollectionOpen(id);
  const setOpen = useSetCollectionOpen();
  const onClick = () => {
    if (isOpen) {
      cloudinaryTracker({ eventName: 'portal_collection_collapse_click', collection_name: collection.name, collection_position: collection.position });
    } else {
      cloudinaryTracker({ eventName: 'portal_collection_expand_click', collection_name: collection.name, collection_position: collection.position });
    }
    return setOpen(id, !isOpen);
  };

  return <CollectionGridPure collection={collection} {...props} onArrowClick={onClick} open={isOpen} />;
});
