import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchCollections, flatDataPages } from './dataService';
import { CollectionInfo } from './PortalData';

export const useLoadCollections = (collectionsInfo: CollectionInfo[], lastCollectionId?: string) => {
  // eslint-disable-next-line
  // @ts-ignore
  const { data, ...rest } = useInfiniteQuery({
    initialPageParam: undefined,
    queryKey: ['collections'],
    // When implementing pagination on server side we don't need to use collectionsInfo in this function to load assets
    queryFn: fetchCollections(collectionsInfo, lastCollectionId),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    refetchOnWindowFocus: false,
  });

  // eslint-disable-next-line
  // @ts-ignore
  const allFetchedCollections = flatDataPages(data);

  return { ...rest, data, allFetchedCollections };
};
