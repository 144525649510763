import React, { FC } from 'react';
import useImage from 'use-image';
import { AssetCard } from './AssetCard';
import { AssetTypes } from './Asset.types';

// use-image constants
const IMG_STATUS_LOADING = 'loading';
const IMG_STATUS_FAILED = 'failed';

export interface AssetCardLoaderProps extends AssetTypes {
  caption?: string;
  captionHeight?: number;
}

export const AssetCardLoader: FC<React.PropsWithChildren<AssetCardLoaderProps>> = (props) => {
  const assetSrc = props.assetSrc;
  const imgStatus = useImage(assetSrc)[1];
  const isLoading = Boolean(assetSrc && imgStatus === IMG_STATUS_LOADING);
  const isError = !assetSrc || imgStatus === IMG_STATUS_FAILED;
  return <AssetCard isLoading={isLoading} isError={isError} {...props} />;
};
