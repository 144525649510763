import React, { FC } from 'react';
import styled from 'styled-components';
import { globalTheme, GlobalTheme } from '@clds/component-theme';
import { Icon } from '@cld/icon';
import useFormat from './useFormat';
import useIcon from './useIcon';

const Wrapper = styled.div`
  ${Icon} {
    width: 1em;
    height: 1em;
    margin-right: ${globalTheme.spacing.xxs};
  }
`;

const ColoredSpan = styled.span<{ color: keyof GlobalTheme['color'] }>`
  color: ${({ color }) => globalTheme.color[color]};
`;

export interface AssetTypeProps {
  color: keyof GlobalTheme['color'];
  format: string;
  type: 'audio' | 'image' | 'video' | 'raw';
}

const AssetType = styled(({ color, format, type, ...rest }) => {
  const icon = useIcon(type);
  const formattedFormat = useFormat(format);

  return (
    <Wrapper data-type="asset-type" {...rest}>
      <Icon name={icon} color={color} data-test="asset-type-icon" />
      <ColoredSpan color={color} data-test="asset-type-format">
        {formattedFormat}
      </ColoredSpan>
    </Wrapper>
  );
})``;

export default AssetType;
