import React, { FC, MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FileDownload } from '@clds/icon';
import { ItemLayout } from '@clds/item';
import { Menu, MenuItem } from '@clds/menu';
import { messages } from '../../../i18n';

const StyledMenu = styled(Menu)`
  min-width: 180px;
`;

interface PopoverOverlayProps {
  onDownload: MouseEventHandler<HTMLElement>;
}

export const PopoverOverlay: FC<React.PropsWithChildren<PopoverOverlayProps>> = ({ onDownload }) => (
  <StyledMenu variant={{ selectionVariant: 'subtle', borderRadius: 'md' }}>
    {onDownload && (
      // TODO: remove the type casting once the type from MenuItem will be fixed
      <MenuItem onClick={onDownload as () => void} data-test="download-button" size="sm">
        <ItemLayout leftSlot={<FileDownload />}>
          <FormattedMessage {...messages.assetDownload} />
        </ItemLayout>
      </MenuItem>
    )}
  </StyledMenu>
);
