import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';

import { asStyledComponent } from '@clds/component-enhancer';

const Wrapper = styled.div`
  span {
    margin-left: ${globalTheme.spacing.xxs};
    margin-right: ${globalTheme.spacing.xxs};
  }
`;

export interface AssetDimensionsProps {
  height: number;
  width: number;
}

export const AssetDimensions = forwardRef<HTMLDivElement, AssetDimensionsProps>(({ height, width, ...props }, ref) => (
  <Wrapper ref={ref} {...props} data-test="dimensions">
    {width}
    <span>x</span>
    {height}
  </Wrapper>
));

export default asStyledComponent(memo(AssetDimensions));
