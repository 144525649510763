// based on https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

const UNITS = [
  {
    size: BigInt('1024'),
    unit: 'Bytes',
    intlUnit: 'byte',
  },
  {
    size: BigInt('1048576'),
    unit: 'KB',
    intlUnit: 'kilobyte',
  },
  {
    size: BigInt('1073741824'),
    unit: 'MB',
    intlUnit: 'megabyte',
  },
  {
    size: BigInt('1099511627776'),
    unit: 'GB',
    intlUnit: 'gigabyte',
  },
  {
    size: BigInt('1125899906842624'),
    unit: 'TB',
    intlUnit: 'terabyte',
  },
  {
    size: BigInt('1152921504606846976'),
    unit: 'PB',
    intlUnit: 'petabyte',
  },
  {
    size: BigInt('1180591620717411303424'),
    unit: 'EB',
    intlUnit: undefined,
  },
  {
    size: BigInt('1208925819614629174706176'),
    unit: 'ZB',
    intlUnit: undefined,
  },
  {
    size: BigInt('1237940039285380274899124224'),
    unit: 'YB',
    intlUnit: undefined,
  },
] as const;
const UNITS_LAST_INDEX = UNITS.length - 1;

export const formatBytes = (bytes: number | bigint, decimals = 2) => {
  const trailingDigits = decimals < 0 || decimals > 20 ? 2 : decimals; // fallback to 2 if illegal

  if (typeof bytes === 'bigint') {
    return formatBigIntBytes(bytes, trailingDigits);
  }

  if (typeof bytes !== 'number') {
    throw new Error('Unsupported value!');
  }

  // avoid zero division
  if (bytes === 0) {
    return {
      value: '0',
      unit: UNITS[0].unit,
      intlUnit: UNITS[0].intlUnit,
    };
  }

  const index = Math.floor(Math.log(bytes) / Math.log(1024));

  if (Number.isNaN(index)) {
    throw new Error('Unsupported value!');
  }

  // fallback for unsupported unit
  if (index > UNITS_LAST_INDEX) {
    return {
      value: `${parseFloat((bytes / Math.pow(1024, UNITS.length - 1)).toFixed(trailingDigits))}`,
      unit: UNITS[UNITS_LAST_INDEX].unit,
      intlUnit: UNITS[UNITS_LAST_INDEX].intlUnit,
    };
  }

  return {
    value: `${parseFloat((bytes / Math.pow(1024, index)).toFixed(trailingDigits))}`,
    unit: UNITS[index].unit,
    intlUnit: UNITS[index].intlUnit,
  };
};

export const formatBigIntBytes = (bytes: bigint, decimals = 2) => {
  if (bytes < UNITS[0].size) {
    return {
      value: bytes.toString(),
      unit: UNITS[0].unit,
      intlUnit: UNITS[0].intlUnit,
    };
  }

  // We skip Bytes due to the first check
  let index = 1;

  while (index < UNITS_LAST_INDEX) {
    const currentUnitSize = UNITS[index].size;
    const previousUnitSize = UNITS[index - 1].size;

    if (bytes >= currentUnitSize) {
      index++;
      continue;
    }

    const baseValue = bytes / previousUnitSize;
    const reminder = bytes % previousUnitSize;

    const valueAsInt = Number(baseValue);
    const reminderAsFraction = Number(reminder) / Number(previousUnitSize);

    const value = (valueAsInt + reminderAsFraction).toFixed(decimals);

    return {
      value: `${parseFloat(value)}`,
      unit: UNITS[index].unit,
      intlUnit: UNITS[index].intlUnit,
    };
  }

  const previousUnitSize = UNITS[UNITS_LAST_INDEX - 1].size;

  const baseValue = bytes / previousUnitSize;
  const reminder = bytes % previousUnitSize;

  const valueAsInt = Number(baseValue);
  const reminderAsFraction = Number(reminder) / Number(previousUnitSize);

  const value = (valueAsInt + reminderAsFraction).toFixed(decimals);

  return {
    value: `${parseFloat(value)}`,
    unit: UNITS[UNITS_LAST_INDEX].unit,
    intlUnit: UNITS[UNITS_LAST_INDEX].intlUnit,
  };
};

export const MBToBytes = (unitsInMB: number) => unitsInMB * 1048576;
