import { isRawAsset } from '@cld/console-apps-types';

export const isAssetDownloadSupported = ({ asset, downloadPresets, preventDownloadOriginal }) => {
  const compatibleDownloadPresets = (downloadPresets ?? []).filter((preset) => preset.presetTypes.includes(asset.type));
  return compatibleDownloadPresets.length > 0 || !preventDownloadOriginal || isRawAsset(asset);
};

export const isCollectionDownloadSupported = ({ collection, downloadPresets, preventDownloadOriginal }) => {
  return collection.assets.some((asset) => isAssetDownloadSupported({ asset, downloadPresets, preventDownloadOriginal }));
};

// Favicon image should be square
// Injects a transfromation to make the image square, before the version component ('v12345678')
export const applyFaviconTransformation = (url) => url?.replace(/\/(?=v\d+\/)/, '/c_pad,ar_1,b_transparent/');
