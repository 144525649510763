/* istanbul ignore file */
import React, { FC } from 'react';
import { filter } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { globalTheme, useMixin } from '@clds/component-theme';
import { Typography } from '@clds/typography';
import { FileDownload } from '@clds/icon';
import { HorizontalSeparator } from '@clds/separator';
import { Item, ItemLayout } from '@clds/item';
import { Button } from '@clds/button';
import { Asset } from '@cld/console-apps-types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SharedPreset } from '@cld/collection/src/types';
import { messages } from '../i18n';
import { StyledPaper, StyledCheckCircle } from './DownloadPresetsModalStyled';

type PresetType = 'image' | 'video';

const DownloadStatus = () => <StyledCheckCircle size="sm" color={useMixin(globalTheme.palette.success)} />;

interface PresetsSectionProps {
  activePresets: Array<string>;
  assets: Array<Asset> | Asset;
  presets: Array<SharedPreset>;
  onPresetDownload: (externalId?: string, presetName?: string) => void;
}

export const PresetsSection: FC<React.PropsWithChildren<PresetsSectionProps>> = ({ activePresets, assets, presets, onPresetDownload }) => {
  const isCollection = Array.isArray(assets);

  const ItemLayoutRightSlot = (externalId?: string, presetName?: string) => {
    const isActive = presetName && activePresets.includes(presetName);
    return isActive ? (
      <DownloadStatus />
    ) : (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onPresetDownload(externalId, presetName);
        }}
      >
        <FileDownload />
      </Button>
    );
  };

  return (
    <>
      <Typography size="sm" type="regular" as="span">
        <FormattedMessage {...messages.presetsDownload} />
      </Typography>
      <StyledPaper borderRadius="sm" depth="none">
        {presets?.map(({ name, presetTypes }) => {
          let displayedSecondaryContent = '';
          const assetId = Array.isArray(assets) ? undefined : assets?.externalId;
          if (isCollection) {
            presetTypes.forEach((presetType: PresetType) => {
              // eslint-disable-next-line
              // @ts-ignore
              const assetsByType = filter(assets, { type: presetType });
              const assetsByTypeCount: number = assetsByType?.length;
              if (assetsByTypeCount > 0) {
                displayedSecondaryContent += ` ${assetsByTypeCount} ${presetType}s`;
              }
            });
          }

          return (
            <>
              <Item borderRadius="sm" size="md" key={name} onClick={() => onPresetDownload(assetId, name)}>
                <ItemLayout
                  secondaryContent={displayedSecondaryContent}
                  // eslint-disable-next-line
                  //@ts-ignore
                  rightSlot={ItemLayoutRightSlot(assetId, name)}
                >
                  {name}
                </ItemLayout>
              </Item>
              <HorizontalSeparator />
            </>
          );
        })}
      </StyledPaper>
    </>
  );
};
