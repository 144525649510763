import React from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import Progress from '@clds/progress';
import { isRawAsset } from '@cld/console-apps-types';
import { download } from '@cld/file-download';
import { NoPreview, ServerError } from '@cld/empty-state';
import ImageFit from '@cld/image-fit';
import { getProperties } from '@cld/console-apps-services';
import useProgressiveImage from '@cld/use-progressive-image';
import withCheckerboardBackground from '@cld/with-checkerboard-background';
import { VideoPlayer } from './VideoPlayer';

const StyledImageWrapper = styled(ImageFit)`
  background-color: ${globalTheme.palette.contrastInvert};
`;

const StyledNoPreview = styled(NoPreview)`
  background-color: ${globalTheme.palette.background};
  border-bottom: 1px solid ${globalTheme.palette.secondary};
`;

const StyledServerError = styled(ServerError)`
  background-color: ${globalTheme.palette.background};
  border-bottom: 1px solid ${globalTheme.palette.secondary};
`;

const ProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

const StyledImage = withCheckerboardBackground(styled.img`
  ${(props) =>
    props.isLoading &&
    css`
      display: none;
    `}
`);

export const Content = ({ asset, onPlay, onPause }) => {
  const { isError, isLoading, src } = useProgressiveImage({
    highQualitySrc: asset.urls.preview,
  });

  // TODO: change asset.urls.originalDownload to use the new download api
  if (isRawAsset(asset)) {
    const downloadAsset = () => asset.urls.originalDownload && download(asset.urls.originalDownload);
    return <StyledNoPreview action="Download" onActionClick={downloadAsset} data-test="no-preview" />;
  }

  if (isError) {
    return <StyledServerError />;
  }

  if (['video', 'audio'].includes(asset.type)) {
    const { customerId: cloudName } = getProperties();
    return <VideoPlayer asset={asset} cloudName={cloudName} playerOptions={{ onPlay, onPause }} />;
  }

  return (
    <StyledImageWrapper>
      {isLoading && (
        <ProgressWrapper>
          <Progress.Circular variant="primary" size="xl" data-test="progress" />
        </ProgressWrapper>
      )}
      <StyledImage src={src} alt={asset.publicId} isLoading={isLoading} data-test="image" />
    </StyledImageWrapper>
  );
};
