import React, { FC } from 'react';
import { lighten } from 'polished';

export interface AssetCardSkeletonProps {
  primaryColor: string;
  secondaryColor: string;
}

/**
 * Internal component of the AssetCard.
 *
 * Renders a skeleton placeholder for the AssetCard. SVG based on http://danilowoz.com/create-content-loader/ version v4.2.2 and adjusted to our needs.
 *
 * TODO - license is MIT, so shouldn't we include the copyright notice and license?
 * TODO - animation was commented out in comparison to the create-content-loader?
 *  Was it for performance reasons? Maybe we should keep it as a prop-enabled option?
 *
 * @param {Object} props
 */
export const AssetCardSkeleton: FC<React.PropsWithChildren<AssetCardSkeletonProps>> = ({ primaryColor, secondaryColor, ...rest }) => {
  const primaryColorLight = lighten(0.1, primaryColor);
  const secondaryColorLight = lighten(0.1, secondaryColor);

  return (
    // SVG Extracted from http://danilowoz.com/create-content-loader/ v4.2.2 and adjusted to our needs (additional, lighter layer of rectangles)
    <svg role="img" aria-label="Loading interface..." viewBox="0 0 274 216" preserveAspectRatio="none" data-test={'asset-card-skeleton'} {...rest}>
      <title>Loading</title>
      <rect x="0" y="0" width="274" height="216" clipPath="url(#mainClip)" style={{ fill: 'url(#mainGradient)' }} />
      <rect x="0" y="0" width="274" height="216" clipPath="url(#secondaryClip)" style={{ fill: 'url(#secondaryGradient)' }} />
      <defs>
        <clipPath id="mainClip">
          <rect x="0" y="0" rx="5" ry="5" width="274" height="186" />
          <rect x="0" y="195" rx="7" ry="7" width="88" height="14" />
        </clipPath>
        <linearGradient id="mainGradient">
          <stop offset="0.016804" stopColor={primaryColor} stopOpacity="1"></stop>
          <stop offset="1.0168" stopColor={primaryColorLight} stopOpacity="1"></stop>
          <stop offset="2.0168" stopColor={primaryColor} stopOpacity="1"></stop>
        </linearGradient>

        <clipPath id="secondaryClip">
          <rect x="4" y="167" rx="7" ry="7" width="45" height="14" />
          <rect x="181" y="167" rx="7" ry="7" width="88" height="14" />
        </clipPath>
        <linearGradient id="secondaryGradient">
          <stop offset="0.016804" stopColor={secondaryColor} stopOpacity="1"></stop>
          <stop offset="1.0168" stopColor={secondaryColorLight} stopOpacity="1"></stop>
          <stop offset="2.0168" stopColor={secondaryColor} stopOpacity="1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
