import React, { FC } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { CustomEmoji, CustomIcons, EmojiData, EmojiSet, EmojiSheetSize, I18n, Picker } from 'emoji-mart';
import { StylableComponentProps } from '@clds/common-definitions';
import { useMixin } from '@clds/component-theme';
import { Face, Flags, Food, LightBulb, Nature, Recent, Search, Star, Symbols, Transport, Trophy } from '@clds/icon';
import { asStyledComponent } from '@clds/component-enhancer';
import { Wrapper } from './EmojiPicker.styled';
import { emojiPickerTheme } from './theme';

export interface EmojiPickerProps extends StylableComponentProps {
  // The title shown when no emojis are hovered
  placeholderTitle: string;
  // The emoji shown when no emojis are hovered, set to an empty string to show nothing
  placeholderEmoji: string;
  // The emoji set, e.g apple
  set?: EmojiSet;
  // Custom icons which can override ths existing one
  icons?: CustomIcons;
  // Localized strings object
  i18n?: I18n;
  // Custom emojis which will show up in their own category
  customEmojis?: CustomEmoji[];
  // The emoji width and height
  emojiSize?: number;
  // The emoji sheet size:
  sheetSize?: EmojiSheetSize;
  // Display skin tones picker.
  showSkinTones?: boolean;
  // Callback function to handle emoji selection
  onSelectCallback?: (emoji: EmojiData) => void;
}
// istanbul ignore next
export const defaultCustomIcons = {
  categories: {
    search: () => <Search size="xs" />,
    recent: () => <Recent size="xs" />,
    people: () => <Face size="xs" />,
    nature: () => <Nature size="xs" />,
    foods: () => <Food size="xs" />,
    activity: () => <Trophy size="xs" />,
    places: () => <Transport size="xs" />,
    objects: () => <LightBulb size="xs" />,
    symbols: () => <Symbols size="xs" />,
    flags: () => <Flags size="xs" />,
    custom: () => <Star size="xs" />,
  },
};

export const EmojiPicker = asStyledComponent<FC<EmojiPickerProps>>(
  ({
    placeholderTitle,
    placeholderEmoji,
    set = 'apple',
    icons = defaultCustomIcons,
    i18n,
    customEmojis,
    emojiSize = 24,
    sheetSize = 64,
    showSkinTones = true,
    onSelectCallback,
    className,
  }) => {
    const selectionColor: string = useMixin(emojiPickerTheme.toolbar.hover.color);

    return (
      <Wrapper className={className}>
        <Picker
          set={set}
          icons={icons}
          i18n={i18n}
          title={placeholderTitle}
          emoji={placeholderEmoji}
          custom={customEmojis}
          emojiSize={emojiSize}
          sheetSize={sheetSize}
          showSkinTones={showSkinTones}
          onSelect={onSelectCallback}
          color={selectionColor}
        />
      </Wrapper>
    );
  }
);
