import React, { FC } from 'react';
import styled from 'styled-components';
import { CommonComponentProps } from '@clds/common-definitions';
import { globalTheme } from '@clds/component-theme';
import { ItemProps } from '@clds/item';
import { Menu, MenuItem, MenuProps } from '@clds/menu';
import Drawer from '@cld/drawer';

export const MobileMenuPaper = styled.div`
  width: calc(100vw - ${globalTheme.spacing.md});
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
`;

//TODO: update the Item api to support customized rounded corners - APPS-7232
const StyledMenu = styled(Menu)`
  border-radius: ${globalTheme.radius.unstable_new.md} ${globalTheme.radius.unstable_new.md} 0 0;
`;

const StyledDrawer = styled(Drawer)`
  width: revert;
  height: revert;
  left: 50%;
  transform: translateX(-50%);
`;

export interface MobileMenuProps extends CommonComponentProps<'div'> {
  /** Callback fired when backdrop was clicked */
  backdropClickHandler: () => void;
  drawerDataTest?: string;
  menuDataTest?: string;
  shouldDisablePortal?: boolean;
  menuProps?: MenuProps;
  'data-test-specifier'?: string;
}

export interface MobileMenuComposition {
  Item: FC<React.PropsWithChildren<ItemProps>>;
}

const MobileMenu: FC<React.PropsWithChildren<MobileMenuProps>> & MobileMenuComposition = ({
  children,
  backdropClickHandler,
  drawerDataTest,
  menuDataTest,
  shouldDisablePortal,
  menuProps,
  'data-test-specifier': dataTestSpecifier,
}) => {
  return (
    <StyledDrawer open onBackdropClick={backdropClickHandler} position="bottom" dataTest={drawerDataTest} shouldDisablePortal={shouldDisablePortal}>
      <MobileMenuPaper>
        <StyledMenu
          data-test={menuDataTest}
          variant={{ selectionVariant: 'subtle', borderRadius: 'md' }}
          {...menuProps}
          data-test-specifier={dataTestSpecifier}
        >
          {children}
        </StyledMenu>
      </MobileMenuPaper>
    </StyledDrawer>
  );
};

MobileMenu.Item = MenuItem;
const MobileMenuItem = MenuItem;

export { MobileMenu, MobileMenuItem };
