import * as yup from 'yup';
// eslint-disable-next-line no-duplicate-imports
import { DateSchema, date, addMethod } from 'yup';
import { parseDate } from './utils';

addMethod<DateSchema>(
  date,
  'lessThan',
  function (
    this: DateSchema,
    dateOrString: Date | string,
    // eslint-disable-next-line no-template-curly-in-string
    message: string = '${originalValue} must be less than ${less}'
  ) {
    const date = parseDate(dateOrString);

    return this.test({
      message: message,
      name: 'lessThan',
      exclusive: true,
      params: {
        less: date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
      },
      test: (value) => !value || value < date,
    });
  }
);

addMethod<DateSchema>(
  date,
  'moreThan',
  function (
    this: DateSchema,
    dateOrString: Date | string,
    // eslint-disable-next-line no-template-curly-in-string
    message: string = '${originalValue} must be more than ${more}'
  ) {
    const date = parseDate(dateOrString);

    return this.test({
      message: message,
      name: 'moreThan',
      exclusive: true,
      params: {
        more: date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
      },
      test: (value) => !value || value > date,
    });
  }
);

export default yup;
