import { useCallback, useEffect, useState } from 'react';
import { THEME, ThemeType, appThemeGen, getNextTheme } from './appTheme.helpers';

export const useSystemThemeDetector = () => {
  const getCurrentTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e: MediaQueryListEvent) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMediaQuery.addEventListener?.('change', mqListener);
    return () => darkThemeMediaQuery.removeEventListener?.('change', mqListener);
  }, []);
  return isDarkTheme ? THEME.DARK : THEME.LIGHT;
};

export const useTheme = () => {
  const [themeName, setThemeName] = useState<ThemeType>((localStorage.getItem('portalAppTheme') as ThemeType) || THEME.SYSTEM);
  const systemTheme = useSystemThemeDetector();
  let newThemeValue = themeName;
  if (newThemeValue === THEME.SYSTEM) {
    newThemeValue = systemTheme;
  }
  const theme = appThemeGen(newThemeValue);

  const toggleTheme = useCallback(() => {
    const nextThemeValue = getNextTheme(themeName);
    setThemeName(nextThemeValue);
    localStorage.setItem('portalAppTheme', nextThemeValue);
  }, [themeName]);

  return { theme, themeName, toggleTheme };
};
