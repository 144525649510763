import PropTypes from 'prop-types';
import React from 'react';
import { usePhone } from '@clds/style-utils';
import { Icon } from '@cld/icon';
import { Columns, Content, StyledButton, StyledNextButton, StyledPrevButton } from './Navigable.styled';

export const Navigable = ({ canNavigateToNext, canNavigateToPrevious, children, onNext, onPrevious, color }) => {
  const isPhone = usePhone();

  return (
    <Columns color={color} data-test="navigable">
      <StyledPrevButton isVisible={canNavigateToPrevious} data-test="collection-share-navigable-previous-button">
        <StyledButton onClick={onPrevious} shape="pill" variant="secondary">
          <Icon name="keyboardArrowLeft" data-test="keyboardArrowLeft" />
        </StyledButton>
      </StyledPrevButton>

      <Content isPhone={isPhone}>{children}</Content>

      <StyledNextButton isVisible={canNavigateToNext} data-test="collection-share-navigable-next-button">
        <StyledButton onClick={onNext} shape="pill" variant="secondary">
          <Icon name="keyboardArrowRight" data-test="keyboardArrowRight" />
        </StyledButton>
      </StyledNextButton>
    </Columns>
  );
};

Navigable.propTypes = {
  canNavigateToNext: PropTypes.bool.isRequired,
  canNavigateToPrevious: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  color: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};
