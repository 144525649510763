// istanbul ignore file
import { KeyboardEvent } from 'react';
import { Node, Editor, Descendant, Text } from 'slate';
import escapeHtml from 'escape-html';
import { CustomEditor } from './CustomEditor';
import { FORMAT_OPTIONS } from './consts';

export const getCharLeangth = (editor: Editor) => Editor.string(editor, []).length;

export const hotkeyHandler = (e: KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  switch (e.key) {
    case 'Enter':
    case 'ArrowUp':
    case 'ArrowDown':
    case 'ArrowRight':
    case 'ArrowLeft': {
      e.stopPropagation();
      break;
    }
    default:
      break;
  }

  if (e.metaKey) {
    switch (e.key) {
      case '&': {
        e.preventDefault();
        editor.insertText('and');
        break;
      }
      case 'b': {
        CustomEditor.toggleMark(editor, FORMAT_OPTIONS.bold.type);
        break;
      }
      case 'i': {
        CustomEditor.toggleMark(editor, FORMAT_OPTIONS.italic.type);
        break;
      }
      case 's': {
        CustomEditor.toggleMark(editor, FORMAT_OPTIONS.strike.type);
        break;
      }
      case 'u': {
        CustomEditor.toggleMark(editor, FORMAT_OPTIONS.underline.type);
        break;
      }
      default:
        break;
    }
  }
};

export const stringSerializer: (nodes: Node[]) => string = (nodes: Node[]) => {
  return nodes.map((n) => Node.string(n)).join('\n');
};

export const htmlSerializer: (nodes: Descendant[]) => string[] = (nodes: Descendant[]) => {
  return nodes.map((node) => {
    if (Text.isText(node)) {
      let nodeElement: string = escapeHtml(node.text);

      if (node.bold) {
        nodeElement = `<strong>${nodeElement}</strong>`;
      }
      if (node.italic) {
        nodeElement = `<em>${nodeElement}</em>`;
      }
      if (node.strike) {
        nodeElement = `<s>${nodeElement}</s>`;
      }
      if (node.underline) {
        nodeElement = `<u>${nodeElement}</u>`;
      }
      if (node.unified) {
        const unified: string = node.unified;
        const parsedEmoji: number[] = unified.split('-').map((unifiedCode) => parseInt(unifiedCode, 16));

        return String.fromCodePoint(...parsedEmoji);
      }

      return nodeElement;
    }

    const children = node.children.map((n) => htmlSerializer([n])).join('');

    switch (node.type) {
      case 'quote':
        return `<blockquote><p>${children}</p></blockquote>`;
      case 'bulleted-list':
        return `<ul>${children}</ul>`;
      case 'list-item':
        return `<li>${children}</li>`;
      case 'paragraph':
        return `<p>${children}</p>`;
      case 'link':
        return `<a href="${escapeHtml(node.url as string)}">${children}</a>`;
      default:
        return children;
    }
  });
};
