/**
 * Issues a file download using native browser streaming.
 *
 * @param src URL to download
 * @param filename of the downloaded file unless taken from the Content-Disposition header
 * @param target default to '_self' (can use any valid href target attribute value)
 */
export const download = (src: string, filename = '', target = '_self') => {
  const a = global.document.createElement('a');
  a.style.setProperty('display', 'none');
  a.href = src;
  a.target = target;
  a.download = filename;

  window.document.body.appendChild(a);
  a.click();
  window.document.body.removeChild(a);
};

/**
 *
 * @param config (downLoadFileConfig) { src: '', filename: '', target:'_self'}
 * src = URL to download
 * filename =  of the downloaded file unless taken from the Content-Disposition header
 * target = default to '_self' (can use any valid href target attribute value)
 * @returns
 */
export const downloadFile = (config: DownLoadFileConfig) => {
  return download(config.src, config.filename ?? '', config.target ?? '_self');
};
interface DownLoadFileConfig {
  src: string;
  filename?: string;
  target?: string;
}
