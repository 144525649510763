export enum NonSocialUploadType {
  Authenticated = 'authenticated',
  Private = 'private',
  Upload = 'upload',
}

export enum SocialUploadType {
  Animoto = 'animoto',
  Asset = 'asset',
  Dailymotion = 'dailymotion',
  Facebook = 'facebook',
  Fetch = 'fetch',
  Foursquare = 'foursquare',
  GPlus = 'gplus',
  Gravatar = 'gravatar',
  Hulu = 'hulu',
  Instagram = 'instagram',
  InstagramName = 'instagram_name',
  List = 'list',
  Multi = 'multi',
  Sprite = 'sprite',
  Text = 'text',
  Twitter = 'twitter',
  TwitterName = 'twitter_name',
  UrlPng = 'urlpng',
  WorldStarHipHop = 'worldstarhiphop',
  Vimeo = 'vimeo',
  YouTube = 'youtube',
}

export type UploadType = NonSocialUploadType | SocialUploadType;
export const UploadTypeValues = [...Object.values(NonSocialUploadType), ...Object.values(SocialUploadType)];
