import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useMixin } from '@clds/component-theme';
import { asStyledComponent } from '@clds/component-enhancer';
import { Slide } from '@clds/transitions';
import { createPortalWithoutEventsPropagation } from '@cld/sandboxed-portal';
import DrawerContainer from './DrawerContainer';
import BackDrop from './BackDrop';
import { drawerTheme } from './theme';
import { DrawerProps } from './types';

const OverlayCss = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
`;

const StyledDrawer = styled.div<{ isOverlay?: boolean }>`
  display: flex;
  height: 100%;
  ${({ isOverlay }) => isOverlay && OverlayCss};
`;

const StyledSlide = styled(Slide)`
  height: 100%;
`;

const Drawer = asStyledComponent(
  ({
    children,
    position,
    size = 'sm',
    className,
    dataTest = 'drawer',
    onBackdropClick,
    open,
    shouldDisablePortal = false,
    timeout,
    ...rest
  }: DrawerProps) => {
    const [isBeingTransitioning, setIsBeingTransitioning] = useState(false);
    const isOverlay = !!position;
    const sizeInPixels = useMixin(drawerTheme.size[size]);
    const cssTransitionProps = {
      onEnter: () => setIsBeingTransitioning(true),
      onExited: () => setIsBeingTransitioning(false),
    };

    const displayOverlay = (open || isBeingTransitioning) && isOverlay;

    const drawer = (
      <StyledDrawer isOverlay={displayOverlay}>
        <DrawerContainer position={position} size={size} className={className} data-test={dataTest} {...rest}>
          <StyledSlide
            in={open}
            transitionDirection={position === 'left' || position === 'right' ? 'horizontal' : 'vertical'}
            startPosition={`${position === 'left' || position === 'top' ? '-' : ''}${sizeInPixels}`}
            endPosition={'0px'}
            cssTransitionProps={cssTransitionProps}
            data-test="slide"
            timeout={timeout}
          >
            {children}
          </StyledSlide>
        </DrawerContainer>

        {displayOverlay && <BackDrop data-test={`${dataTest}-backdrop`} onClick={onBackdropClick} />}
      </StyledDrawer>
    );

    return shouldDisablePortal ? drawer : createPortalWithoutEventsPropagation(drawer, document.body);
  }
);

export default Drawer;
