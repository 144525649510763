import { ResourceType } from '../ResourceType';

/**
 * Normalizes the asset format into a non-optional value.
 *
 * Data from backend has undefined format for raw assets.
 * It should be extracted from the public id.
 *
 * @param resourceType of the asset
 * @param publicId of the asset
 * @param format of the asset
 */
export const normalizeAssetFormat = (resourceType: string, publicId: string, format?: string | null) => {
  return resourceType === ResourceType.Raw ? publicId.substring(publicId.lastIndexOf('.') + 1) : format;
};
