import { useEffect, useReducer } from 'react';

export const actions = {
  reload: 'reload',
  lowQualityLoaded: 'low-quality-loaded',
  highQualityLoaded: 'high-quality-loaded',
  error: 'error',
};

export const progressiveImageReducer = (state, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actions.reload:
      return {
        isError: false,
        isLoading: true,
        isLowQuality: false,
        src: null,
      };
    case actions.lowQualityLoaded:
      return state.src
        ? state
        : {
            isError: false,
            isLoading: false,
            isLowQuality: true,
            src: action.src,
          };
    case actions.highQualityLoaded:
      return {
        isError: false,
        isLoading: false,
        isLowQuality: false,
        src: action.src,
      };
    case actions.error:
      return {
        isError: true,
        isLoading: false,
        isLowQuality: false,
        src: null,
      };
    default:
      return state;
  }
};

export const useProgressiveImage = ({ lowQualitySrc, highQualitySrc }) => {
  const [{ isError, isLoading, isLowQuality, src }, dispatch] = useReducer(progressiveImageReducer, {});

  useEffect(() => {
    dispatch({ type: actions.reload });

    const lowQualityImage = new Image();
    lowQualityImage.onload = () => dispatch({ type: actions.lowQualityLoaded, src: lowQualitySrc });

    const highQualityImage = new Image();
    highQualityImage.onload = () => dispatch({ type: actions.highQualityLoaded, src: highQualitySrc });
    highQualityImage.onerror = () => dispatch({ type: actions.error });

    if (lowQualitySrc && highQualitySrc) {
      lowQualityImage.src = lowQualitySrc;
    }
    highQualityImage.src = highQualitySrc;
  }, [lowQualitySrc, highQualitySrc]);

  return { isError, isLoading, isLowQuality, src };
};
