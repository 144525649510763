import React, { ReactNode, useRef } from 'react';
import { useMergeRefs } from 'use-callback-ref';
import { unselect, Unselect } from '@clds/compound-components';
import { useFocusManagement, useFocusOnMount } from '../DropdownMenu.hooks';
import { DropdownMenuContextProvider } from '../DropdownMenuContext';
import { useConnectedDropdownTrigger } from './useConnectedDropdownTrigger';
import { DropdownMenuState } from './useDropdownMenuState';
import { useDropdownRegistry } from './useDropdownRegistry';

export interface DropdownMenuStateProps {
  trigger: ReactNode;
  items: ReactNode;
  focusOnClose?: boolean;
}

export const useDropdownMenu = <NavigationRef extends HTMLElement = HTMLElement>(props: DropdownMenuStateProps, state: DropdownMenuState) => {
  const triggerRef = useRef<HTMLElement | null>(null);
  const menuRef = useRef<NavigationRef | null>(null);
  const focusOnCloseWithDefault = props.focusOnClose ?? true;
  const { context, isClosable } = useDropdownRegistry();
  useFocusManagement(menuRef, triggerRef, state.isOpened, focusOnCloseWithDefault);

  const navigationRef = useMergeRefs([menuRef, useFocusOnMount]);
  const connectedTrigger = useConnectedDropdownTrigger(props.trigger, triggerRef, state.isOpened);
  const connectedItems = <DropdownMenuContextProvider value={context}>{props.items}</DropdownMenuContextProvider>;

  return {
    connectedTrigger,
    connectedItems,
    navigationRef,
    menuProps: {
      onSelect: (id: string | Unselect) => state.select(id, id === unselect || isClosable(id)),
      focusedId: state.focusedId,
      onFocusedIdChange: state.setFocusedId,
      selectedId: state.selectedId,
    },
    popoverProps: {
      visible: state.isOpened,
      onVisibleChange: state.setOpened,
    },
  } as const;
};
