import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { withRenderOnHover } from '@cld/with-conditional-render';
import AssetCard from '@cld/asset-card';
import { captionHeight } from '../../constants/asset';
import { ItemOverlay } from './item/ItemOverlay';

const ContentOnHover = withRenderOnHover(styled.div`
  height: 100%;
`);

export const CollectionGridItem = ({ 'data-test-specifier': dataTestSpecifier, asset, onClick, onDownload, onMenuClick }) => {
  return (
    <LazyLoad overflow offset={500} data-test="lazy-load-collection-grid-item">
      <AssetCard
        data-test-specifier={dataTestSpecifier}
        assetSrc={asset.urls.thumbnail}
        assetType={asset.type}
        assetFormat={asset.format}
        assetWidth={asset.width}
        assetHeight={asset.height}
        assetBytes={asset.bytes}
        assetDuration={asset.duration}
        caption={asset.displayName || asset.filename}
        captionHeight={captionHeight}
        data-test="collection-grid-item"
      >
        <ContentOnHover>
          <ItemOverlay onClick={onClick} onDownload={onDownload} onMenuClick={onMenuClick} />
        </ContentOnHover>
      </AssetCard>
    </LazyLoad>
  );
};

CollectionGridItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDownload: PropTypes.func,
  onMenuClick: PropTypes.func.isRequired,
};

export default CollectionGridItem;
