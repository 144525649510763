import { useIntl } from 'react-intl';
import React, { FC, ReactNode } from 'react';
import { LanguageProvider } from '@cld/intl';
import { LinkButton } from '@clds/button';
import EmptyState, { CommonEmptyStateProps } from '../../EmptyState';
import { loadMessages, messages } from '../../i18n';
import notFound from './notFound.svg';

export interface NotFoundProps extends CommonEmptyStateProps {
  /** Action button text */
  action?: ReactNode;
  /** Action button onClick handler */
  onActionClick?(): void;
  /** Temporary TS fix */
  actionText?: Record<string, string>;
}

const NotFoundRaw: FC<NotFoundProps> = ({ action, onActionClick, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <EmptyState imageSrc={notFound} imageHeight={145} imageWidth={138} title={formatMessage(messages.notFoundTitle)} {...rest}>
      {action && <LinkButton onClick={onActionClick}>{action}</LinkButton>}
    </EmptyState>
  );
};

export const NotFound: FC<NotFoundProps> = (props) => (
  <LanguageProvider loadMessages={loadMessages}>
    <NotFoundRaw {...props} data-test-specifier="app-error" />
  </LanguageProvider>
);
