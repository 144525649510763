import { useEffect, useState } from 'react';

export interface PromiseState<DataType> {
  isLoading: boolean;
  data?: DataType;
  error?: Error;
}

export const usePromiseState = <DataType>(promise: Promise<DataType>): PromiseState<DataType> => {
  const [promiseState, setPromiseState] = useState<PromiseState<DataType>>({
    isLoading: true,
  });

  useEffect(() => {
    promise
      .then((data) => {
        setPromiseState({
          isLoading: false,
          data,
        });
      })
      .catch((error: Error) => {
        setPromiseState({
          isLoading: false,
          error,
        });
      });
  }, [promise]);

  return promiseState;
};
