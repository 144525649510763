import React from 'react';
import styled from 'styled-components';
import { captionHeight, imageHeight, imageWidth } from '../../constants/asset';

export const AssetGridIE11Fallback = styled(({ children, ...props }) => (
  // we have to insert zero size elements only to make justify-content:center work for last row
  // https://stackoverflow.com/a/22018710
  <div {...props}>
    {children}
    <>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </>
  </div>
))`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 0;
  > * {
    width: ${imageWidth}px;
    height: ${imageHeight + captionHeight}px;
    margin: 10px;
    &:empty {
      height: 0;
      margin: 0 10px;
    }
  }
`;
