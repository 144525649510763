import { useContext } from 'react';

import { DataContext } from './DataContext';

export const useData = () => {
  const config = useContext(DataContext);

  if (config === undefined) {
    throw new Error('No value provided in DataContext! It should be passed through DataProvider.');
  }

  return config;
};
