/* istanbul ignore file */
import React, { FC, useCallback, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Photo, Video, FileDownload, ZipFolder, File } from '@clds/icon';
import { ItemLayout } from '@clds/item';
import { Paper } from '@clds/paper';
import { Button } from '@clds/button';
import { Asset, isRawAsset } from '@cld/console-apps-types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  getCollectionCalculatedSize,
  getAssetCalculatedSize,
  getAssetFormat,
  getAssetDimensions,
} from '@cld/collection/src/components/DowloadPresetsModal/utils';
import { messages } from '../i18n';
import { StyledItem } from './DownloadPresetsModalStyled';

const ItemRightSlot = (itemType?: string) => {
  switch (itemType) {
    case 'raw':
      return <File />;
    case 'zip':
      return <ZipFolder />;
    case 'video':
      return <Video />;
    default:
      return <Photo />;
  }
};

interface OriginalDownloadSectionProps {
  assets: Asset | Array<Asset>;
  onOriginalDownload: (externalId?: string) => void;
}

export const OriginalDownloadSection: FC<React.PropsWithChildren<OriginalDownloadSectionProps>> = ({ assets, onOriginalDownload }) => {
  const isColllection = Array.isArray(assets);
  let downloadableItemDescription;
  let assetsCount;
  let asset;

  if (isColllection) {
    const collection = assets;
    assetsCount = collection?.length;
    const { value, unit } = getCollectionCalculatedSize(collection);
    downloadableItemDescription = `${value} ${unit}`;
  } else {
    asset = assets;
    const { value, unit } = getAssetCalculatedSize(asset);
    const assetDescriptionByType = isRawAsset(asset) ? '' : ` | ${getAssetDimensions(asset)}`;
    downloadableItemDescription = `${value}${unit} ${assetDescriptionByType} | ${getAssetFormat(asset)}`;
  }

  const OriginalDownloadButton = (externalId?: string) => (
    <Button
      onClick={useCallback(
        (e: MouseEvent) => {
          e.stopPropagation();
          onOriginalDownload(externalId);
        },
        [externalId]
      )}
    >
      <FileDownload />
    </Button>
  );

  return (
    <Paper depth="sm" onClick={() => (isColllection ? onOriginalDownload() : onOriginalDownload(assets?.externalId))}>
      <StyledItem size="md">
        <ItemLayout
          rightSlot={isColllection ? OriginalDownloadButton() : OriginalDownloadButton(assets?.externalId)}
          secondaryContent={downloadableItemDescription}
          leftSlot={ItemRightSlot(isColllection ? 'zip' : asset?.type)}
        >
          {isColllection ? `${assetsCount || ''} Original Assets` : <FormattedMessage {...messages.original} />}
        </ItemLayout>
      </StyledItem>
    </Paper>
  );
};
