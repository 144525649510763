import { useCallback } from 'react';

/** Let's you easily save and use a value in local storage. Receives a local storage key, and returns a value and setter function **/
export const usePersistency = (localStorageKey: string | undefined) => {
  const value = (localStorageKey && localStorage.getItem(localStorageKey)) || null;

  const setPersistentValue = useCallback(
    (value: string | number) => {
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, value.toString());
      }
    },
    [localStorageKey]
  );

  return { value, setPersistentValue };
};
