// istanbul ignore file

import { createContext } from 'react';
import { PromiseState } from '@cld/use-promise-state';

import { CollectionInfo, PortalCollections, PortalInfo } from '../../services/dataService/PortalData';

export const DataContext = createContext<
  | {
      info: PromiseState<PortalInfo>;
      collections?: PortalCollections;
      fetchCollectionsByIds: (ids: string[]) => Array<CollectionInfo>;
      expiresIn: number;
    }
  | undefined
>(undefined);
