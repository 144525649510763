import * as yup from 'yup';
// eslint-disable-next-line no-duplicate-imports
import { NumberSchema, number, addMethod } from 'yup';
import { isUndefined } from 'lodash';

addMethod<NumberSchema>(
  number,
  'lessThanEqual',
  function (this: NumberSchema, maxNumber: number, message = ({ value }: { value: number }) => `${value} must be less than or equal to ${maxNumber}`) {
    return this.test({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      message: message,
      name: 'lessThanEqual',
      exclusive: true,
      params: { maxNumber },
      test: (value) => isUndefined(value) || value <= maxNumber,
    });
  }
);

addMethod<NumberSchema>(
  number,
  'moreThanEqual',
  function (this: NumberSchema, minNumber: number, message = ({ value }: { value: number }) => `${value} must be more than or equal to ${minNumber}`) {
    return this.test({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      message: message,
      name: 'moreThanEqual',
      exclusive: true,
      params: { minNumber },
      test: (value) => isUndefined(value) || value >= minNumber,
    });
  }
);

export default yup;
