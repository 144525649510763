import React from 'react';
import { ModalNextProvider, ModalNext } from '@clds/modal';
import { useData } from '../data/useData';
import { useExpirableSession } from '../../common/hooks/useExpirableSession';
import { RefreshModal } from '../../components/RefreshModal/RefreshModal';

export const SessionTimeout = ({ children }) => {
  const { expiresIn } = useData();
  const isSessionExpired = useExpirableSession(expiresIn - 3); // display popup 3 minutes before expiry

  return (
    <div>
      {children}
      <ModalNextProvider>
        <ModalNext isOpen={isSessionExpired}>
          <RefreshModal />
        </ModalNext>
      </ModalNextProvider>
    </div>
  );
};
export default SessionTimeout;
