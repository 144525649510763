import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import Progress from '@clds/progress';

const CldVideoPlayer = lazy(() => import('@cld/video-player'));

const ProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

const Loader = () => (
  <ProgressWrapper>
    <Progress.Circular variant="primary" size="xl" />
  </ProgressWrapper>
);

export const VideoPlayer = ({ asset, cloudName, playerOptions }) => (
  <Suspense fallback={<Loader />}>
    <CldVideoPlayer asset={asset} cloudName={cloudName} playerOptions={playerOptions} />
  </Suspense>
);
