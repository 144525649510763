import { createContext } from 'react';
import { Undefinable } from '@clds/common-definitions';
import { createUseDefinedContext } from '@clds/compound-components';

export interface DropdownMenuContext {
  registerNonClosableMenuItem: (id: string) => void;
  unregisterNonClosableMenuItem: (id: string) => void;
}

const context = createContext<Undefinable<DropdownMenuContext>>(undefined);

export const DropdownMenuContextProvider = context.Provider;
export const useDropdownMenuContext = createUseDefinedContext(context, 'Please wrap your component with <DropdownMenu>');
