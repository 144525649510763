import React from 'react';
import styled from 'styled-components';
import { combineValues, globalTheme } from '@clds/component-theme';
import { media } from '@clds/style-utils';
import { asStyledComponent } from '@clds/component-enhancer';
import Footer from '@cld/footer';
import { Header } from '../Header';
import { COLLECTIONS_CONTAINER_ID } from '../App.constants';
import Progress from '@clds/progress';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  min-height: -available;
`;

const StyledBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${globalTheme.palette.secondary};
  flex: 1;
  overflow: hidden;
  @media ${media.phone()} {
    flex-direction: column;
  }
`;

const StyledNavigation = styled.div`
  align-self: flex-start;
  width: 20%;
  min-width: 200px;
  max-width: 526px;
  height: 100%;
  overflow-y: auto;

  nav {
    border-right: none;
  }

  @media ${media.phone()} {
    display: none;
  }
`;

const StyledMainLayoutProgressWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${combineValues(globalTheme.palette.surface, (color) => `${color}6A`)};
  z-index: 10;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${globalTheme.palette.surface};
  border-left: 1px solid ${globalTheme.palette.secondary};
  height: 100%;
  overflow-y: auto;
`;

const StyledGrid = styled.div`
  flex: 1;
`;

export const MainLayoutBase = ({ navigation, content, contentRef, name, description, logo, isPendingCollectionNavigation, ...rest }) => {
  return (
    <StyledRoot id={COLLECTIONS_CONTAINER_ID}>
      {name && <Header title={name} description={description} logo={logo} contentRef={contentRef} />}
      <StyledBody {...rest}>
        <StyledNavigation>{navigation}</StyledNavigation>
        <StyledContent ref={contentRef}>
          <StyledGrid>{content}</StyledGrid>
        </StyledContent>
        {isPendingCollectionNavigation && (
          <StyledMainLayoutProgressWrapper>
            <Progress.Circular variant="primary" size="lg" />
          </StyledMainLayoutProgressWrapper>
        )}
      </StyledBody>
      <Footer />
    </StyledRoot>
  );
};

export const MainLayout = asStyledComponent(MainLayoutBase);
