import React, { FC } from 'react';
import styled from 'styled-components';
import { asStyledComponent } from '@clds/component-enhancer';
import { Icon } from '@cld/icon';
import useIcon from './useIcon';

const Wrapper = styled.div`
  ${Icon} {
    width: 1em;
    height: 1em;
  }
`;

export interface AssetTypeIconProps {
  color: string;
  type: 'audio' | 'image' | 'video' | 'raw';
}

export const AssetTypeIcon: FC<React.PropsWithChildren<AssetTypeIconProps>> = ({ color, type, ...rest }) => {
  const icon = useIcon(type);
  return (
    <Wrapper data-test="asset-type" {...rest}>
      <Icon color={color} name={icon} data-test="asset-type-icon" />
    </Wrapper>
  );
};

export default asStyledComponent(AssetTypeIcon);
