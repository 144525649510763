import * as yup from '@cld/yup-extra';

import { AssetType } from '../AssetType';
import { NonSocialUploadType } from '../UploadType';
import { assetBaseSchema } from './AssetBase';

export const rawAssetSchema = assetBaseSchema
  .shape({
    type: yup.mixed().oneOf([AssetType.Raw]).required(),
    uploadType: yup.mixed().oneOf(Object.values(NonSocialUploadType)).required(),
  })
  .required();

export type RawAsset = yup.InferType<typeof rawAssetSchema>;
