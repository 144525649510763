import styled from 'styled-components';
import { typography } from '@clds/typography';
import { emojiPickerTheme } from './theme';

export const Wrapper = styled.div`
  .emoji-mart {
    background-color: ${emojiPickerTheme.container.background.color};
    box-shadow: ${emojiPickerTheme.container.shadow};
    font-family: ${emojiPickerTheme.container.font};

    .emoji-mart-search input {
      background-color: ${emojiPickerTheme.input.background.color};
      border-color: ${emojiPickerTheme.container.border.color};
      ${typography.xs.highContrast};
    }

    .emoji-mart-search input::placeholder {
      color: ${emojiPickerTheme.container.border.color};
      opacity: 1; /* Firefox */
    }

    .emoji-mart-search-icon {
      top: ${emojiPickerTheme.input.icon.layout.top};
      svg {
        fill: ${emojiPickerTheme.toolbar.color};
      }
    }

    .emoji-mart-category-label span {
      ${typography.xs.highContrast}
      background-color: ${emojiPickerTheme.container.background.color};
    }

    .emoji-mart-title-label {
      ${typography.md.regular};
    }

    .emoji-mart-preview-shortnames,
    .emoji-mart-preview-name {
      ${typography.xs.highContrast};
    }

    .emoji-mart-anchor {
      color: ${emojiPickerTheme.toolbar.color};
    }

    .emoji-mart-category-list button {
      cursor: pointer;
    }

    .emoji-mart-category-list {
      li:focus,
      button:focus {
        outline: none;
      }
    }

    .emoji-mart-skin:focus {
      outline: none;
    }

    button {
      cursor: pointer;
    }
  }
`;
