import React, { ComponentProps, FC, MutableRefObject, ReactElement, ReactNode, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { insetFocusRing } from '@clds/style-utils';
import { useItemContext } from '@clds/item';
import { useNavigationContext } from '../context/navigationContext';

const LeftSlotWrapper = styled.div`
  margin-right: ${globalTheme.spacing.xs};
`;

const StyledAnchor = styled.a<{ isSelected?: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      &[data-focus-visible-added]:before {
        box-shadow: none !important;
      }
    `};
`;

type DefaultLinkComponentProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'ref' | 'as'>;

interface BaseLinkComponentProps {
  children?: ReactNode | ReactNode[];
  ref: MutableRefObject<unknown>;
  className?: string;
  tabIndex?: number;
}

export type NavigationLayoutLinkProps<ComponentType extends React.ComponentType> =
  | (Partial<ComponentProps<ComponentType>> & {
      leftSlot?: ReactNode;
      className?: string;
      children?: ReactNode;
      LinkComponent: ComponentType;
    })
  | ({
      leftSlot?: ReactNode;
      className?: string;
      children?: ReactNode;
    } & DefaultLinkComponentProps);

// Unfortunately `styled-components` do not play well with generic types see https://github.com/styled-components/styled-components/issues/1803
export const NavigationLayoutLink: <ComponentType extends React.ComponentType>(props: NavigationLayoutLinkProps<ComponentType>) => ReactElement = styled(
  <ComponentType extends FC>({ leftSlot, children, className, ...rest }: NavigationLayoutLinkProps<ComponentType>) => {
    const { isSelected } = useItemContext();
    const { isDisabled } = useNavigationContext();
    const linkRef = useRef<HTMLAnchorElement | null>(null);

    useEffect(() => {
      if (isSelected && linkRef.current && !isDisabled) {
        linkRef.current.focus();
      }
    }, [isSelected, isDisabled]);

    if ('LinkComponent' in rest) {
      const { LinkComponent, ...linkProps } = rest;
      const TypedLinkComponent = LinkComponent as FC<React.PropsWithChildren<BaseLinkComponentProps>>;

      return (
        <TypedLinkComponent ref={linkRef} tabIndex={isDisabled ? -1 : 0} className={className} {...linkProps}>
          <LeftSlotWrapper>{leftSlot}</LeftSlotWrapper>
          {children}
        </TypedLinkComponent>
      );
    }

    return (
      <StyledAnchor isSelected={isSelected} ref={linkRef} tabIndex={isDisabled ? -1 : 0} className={className} {...rest}>
        <LeftSlotWrapper>{leftSlot}</LeftSlotWrapper>
        {children}
      </StyledAnchor>
    );
  }
)`
  &,
  &:visited {
    color: inherit;
    text-decoration: none !important;
  }

  padding-left: calc((var(--depth) * (${globalTheme.spacing.lg} - ${globalTheme.spacing.xxs})) + ${globalTheme.spacing.sm});
  padding-top: ${globalTheme.spacing.xs};
  padding-bottom: ${globalTheme.spacing.xs};
  display: flex;
  align-items: center;
  ${insetFocusRing()}
`;
