import styled, { css } from 'styled-components';
import React, { ReactNode } from 'react';
import { NavigationContextProvider } from '../context/navigationContext';

const Root = styled.nav<{ isDisabled?: boolean }>`
  --depth: 0;

  li {
    margin: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    --depth: 0;
  }

  ul ul {
    --depth: 1;
  }

  ul ul ul {
    --depth: 2;
  }

  ul ul ul ul {
    --depth: 3;
  }

  ul ul ul ul ul {
    --depth: 4;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.25;
    `}
`;

export interface NavigationContainerProps {
  children?: ReactNode;
  isDisabled?: boolean;
  className?: string;
}

export const NavigationContainer = ({ children, isDisabled, ...rest }: NavigationContainerProps) => (
  <NavigationContextProvider isDisabled={Boolean(isDisabled)}>
    <Root {...rest} isDisabled={isDisabled} aria-disabled={isDisabled}>
      <ul>{children}</ul>
    </Root>
  </NavigationContextProvider>
);
