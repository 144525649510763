export const loadMessages = async (locale: string) => {
  if (locale === 'de-DE') {
    return import('./de-DE.json');
  }

  if (locale === 'es-ES') {
    return import('./es-ES.json');
  }

  if (locale === 'fr-FR') {
    return import('./fr-FR.json');
  }

  if (locale === 'it-IT') {
    return import('./it-IT.json');
  }

  if (locale === 'nl-NL') {
    return import('./nl-NL.json');
  }

  return Promise.resolve({});
};

export { messages } from './messages';
