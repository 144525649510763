import { addMethod, ArraySchema, array } from 'yup';

type SchemaValue = string | ((...args: unknown[]) => void);

addMethod<ArraySchema<SchemaValue>>(
  array,
  'noDuplications',
  function (
    this: ArraySchema<SchemaValue>,
    // eslint-disable-next-line no-template-curly-in-string
    message: string = 'Array ${array} contains duplications ${duplications}'
  ) {
    return this.test('noDuplications', message, function (value) {
      const { createError } = this;

      const duplications = !Array.isArray(value)
        ? []
        : value.reduce((acc: SchemaValue[], el, i, arr) => {
            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) {
              acc.push(el);
            }

            return acc;
          }, []);

      // istanbul ignore next
      const fallbackValue = value || [];

      return (
        !duplications.length || createError({ message, params: { duplications: `[${duplications.join(', ')}]`, array: `[${fallbackValue.join(', ')}]` } })
      );
    });
  }
);

addMethod<ArraySchema<SchemaValue>>(array, 'defined', function () {
  return this.test('defined', '{path} must be defined', (array) => Array.isArray(array));
});
