import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { LanguageProvider } from '@cld/intl';
import { LinkButton } from '@clds/button';
import { loadMessages, messages } from '../../i18n';
import { ALL_SYSTEMS_OPERATIONAL_URL, SUPPORT_REQUEST_URL, getLink } from '../SomethingWentWrong/SomethingWentWrong.utils';
import { SomethingWentWrongLayout, SomethingWentWrongLayoutProps } from '../SomethingWentWrong/SomethingWentWrong';
import { StyledSomethingWentWrongVertical } from '../SomethingWentWrong/SomethingWentWrong.styled';
import { ButtonWrapper } from './ServerError.styled';

type ServerErrorProps = SomethingWentWrongLayoutProps;

export const ServerErrorRaw: FC<React.PropsWithChildren<ServerErrorProps>> = ({ className, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <StyledSomethingWentWrongVertical className={className}>
      <SomethingWentWrongLayout
        imageSrc="https://res.cloudinary.com/prod/image/upload/v1680430652/mpex/serverError.svg"
        title={formatMessage(messages.serverErrorTitle)}
        subTitle={formatMessage(messages.serverErrorSubtitle, { strong: getLink(ALL_SYSTEMS_OPERATIONAL_URL) })}
        data-test="server-error"
        {...rest}
      >
        <ButtonWrapper>
          <LinkButton variant="primary" tone="solid" href={SUPPORT_REQUEST_URL} target="_blank" rel="noopener noreferrer">
            {formatMessage(messages.serverErrorButton)}
          </LinkButton>
        </ButtonWrapper>
      </SomethingWentWrongLayout>
    </StyledSomethingWentWrongVertical>
  );
};

export const ServerError: FC<React.PropsWithChildren<ServerErrorProps>> = (props) => (
  <LanguageProvider loadMessages={loadMessages}>
    <ServerErrorRaw {...props} data-testid="server-error" />
  </LanguageProvider>
);
