import React, { FC, HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../../i18n';
import {
  StyledTitle,
  StyledSomethingWentWrongWrapper,
  StyledContentWrapper,
  StyledSubTitle,
  StyledContentTitle,
  StyledHeader,
  StyledTextWrapper,
  StyledList,
  StyledListItem,
} from './SomethingWentWrong.styled';
import {
  ALL_SYSTEMS_OPERATIONAL_URL,
  SUPPORT_REQUEST_URL,
  ERROR_DOG_URL,
  getLink,
  onClickRefresh,
  SomethingWentWrongProps,
  SomethingWentWrongResponsive,
} from './SomethingWentWrong.utils';
import { SomethingWentWrongMobile } from './SomethingWentWrongMobile';

export type SomethingWentWrongLayoutProps = SomethingWentWrongProps & HTMLAttributes<HTMLDivElement>;

export const SomethingWentWrongLayout: FC<React.PropsWithChildren<SomethingWentWrongLayoutProps>> = ({
  title,
  subTitle,
  imageSrc,
  imageHeight,
  imageWidth,
  children,
  ...rest
}) => (
  <StyledContentWrapper {...rest}>
    <StyledTextWrapper>
      <StyledHeader>
        <StyledTitle data-test="something-went-wrong-title">{title}</StyledTitle>
        <StyledSubTitle data-test="something-went-wrong-subtitle">{subTitle}</StyledSubTitle>
      </StyledHeader>
      {children}
    </StyledTextWrapper>
    <img alt={title} src={imageSrc} height={imageHeight} width={imageWidth} />
  </StyledContentWrapper>
);

export const SomethingWentWrongDefaultContent = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StyledContentTitle>{formatMessage(messages.somethingWentWrong_contentTitle)}</StyledContentTitle>
      <StyledList>
        <StyledListItem>{formatMessage(messages.somethingWentWrong_refreshThePage, { strong: onClickRefresh })}</StyledListItem>
        <StyledListItem>{formatMessage(messages.somethingWentWrong_returnPreviousPage)}</StyledListItem>
        <StyledListItem>{formatMessage(messages.somethingWentWrong_checkSystemStatus, { strong: getLink(ALL_SYSTEMS_OPERATIONAL_URL) })}</StyledListItem>
      </StyledList>
    </>
  );
};

export const SomethingWentWrongCommon: FC<React.PropsWithChildren<SomethingWentWrongProps>> = ({ children, ...rest }) => {
  const { formatMessage } = useIntl();

  return (
    <SomethingWentWrongLayout
      imageSrc={ERROR_DOG_URL}
      title={formatMessage(messages.somethingWentWrong_title)}
      subTitle={formatMessage(messages.somethingWentWrong_subTitle, { strong: getLink(SUPPORT_REQUEST_URL) })}
      {...rest}
    >
      {children || <SomethingWentWrongDefaultContent />}
    </SomethingWentWrongLayout>
  );
};

export const SomethingWentWrongDesktop: FC<React.PropsWithChildren<SomethingWentWrongProps>> = (props) => (
  <StyledSomethingWentWrongWrapper>
    <SomethingWentWrongCommon imageHeight={348} imageWidth={320} {...props} />
  </StyledSomethingWentWrongWrapper>
);

export const SomethingWentWrong: FC<React.PropsWithChildren<SomethingWentWrongResponsive>> = ({ isMobile, ...rest }) =>
  isMobile ? <SomethingWentWrongMobile {...rest} /> : <SomethingWentWrongDesktop {...rest} />;
