import { useIntl } from 'react-intl';
import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import styled from 'styled-components';
import { globalTheme, useMixin } from '@clds/component-theme';
import { Tooltip } from '@clds/tooltip';
import Popover from '@clds/popover';
import { Icon } from '@cld/icon';
import { messages } from '../../../i18n';
import { PopoverOverlay } from './PopoverOverlay';

const PopoverWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopoverIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${globalTheme.spacing.sm};
`;

interface ItemOverlayProps {
  onClick: MouseEventHandler;
  onDownload: () => void;
  onMenuClick: MouseEventHandler;
}

export const ItemOverlay: FC<React.PropsWithChildren<ItemOverlayProps>> = ({ onClick, onDownload, onMenuClick }) => {
  const { formatMessage } = useIntl();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const whiteColor = useMixin(globalTheme.color.white);

  const handleMenuIconClick: MouseEventHandler = (event) => {
    setIsTooltipVisible(false);
    onMenuClick(event);
  };
  const handleMouseLeave = () => setIsPopoverVisible(false);
  const handleTooltipVisibleChange = (visible: boolean) => setIsTooltipVisible(!isPopoverVisible && visible);

  return (
    <PopoverWrapper onClick={onClick} onMouseLeave={handleMouseLeave}>
      {onDownload && (
        <>
          <Popover
            destroyTooltipOnHide
            getTooltipContainer={() => menuButtonRef.current as HTMLDivElement}
            onVisibleChange={setIsPopoverVisible}
            overlay={
              <PopoverOverlay
                onDownload={(event) => {
                  // stop the asset from getting previewed
                  event.stopPropagation();
                  setIsPopoverVisible(false);

                  onDownload?.();
                }}
              />
            }
            paperProps={{ isPadded: false }}
            placement="bottom"
            trigger="click"
            visible={isPopoverVisible}
          >
            <PopoverIcon onClick={handleMenuIconClick} ref={menuButtonRef} data-test="menu-button">
              <Tooltip onVisibleChange={handleTooltipVisibleChange} visible={isTooltipVisible} title={formatMessage(messages.menu)}>
                {/* Icon should stay white in both theme modes */}
                <Icon name="moreVert" size="sm" color={whiteColor} />
              </Tooltip>
            </PopoverIcon>
          </Popover>
          {/* Icon should stay white in both theme modes */}
          <Icon name="eyeOn" size="xl" color={whiteColor} />
        </>
      )}
    </PopoverWrapper>
  );
};
