/* istanbul ignore file */
import { Language } from '@clds/icon';
import { MenuItem } from '@clds/menu';
import { ItemLayout } from '@clds/item';
import React, { FC, useContext } from 'react';
import { Button } from '@clds/button';
import { DropdownMenu } from '@cld/dropdown-menu';
import { LocaleContext } from '@cld/intl';
import { useSupportedLanguages } from './useSupportedLanguages';

export const DesktopLanguageSelector = () => {
  const { languages, setLocale } = useSupportedLanguages();
  const locale = useContext(LocaleContext);

  return (
    <DropdownMenu
      minWidth="sm"
      placement="bottomLeft"
      getTooltipContainer={(containerElement) => containerElement}
      items={languages.map(({ languageKey, languageLabel }) => (
        <MenuItem key={languageKey} isSelected={languageKey === locale} showCheckIconWhenSelected onClick={() => setLocale(languageKey)}>
          <ItemLayout>{languageLabel}</ItemLayout>
        </MenuItem>
      ))}
    >
      <Button size="sm" tone="plain" variant="secondary" rightSlot={<Language />} />
    </DropdownMenu>
  );
};
