/* istanbul ignore file */
import { Language } from '@clds/icon';
import { Button } from '@clds/button';
import React, { useContext, useState } from 'react';
import { ItemLayout } from '@clds/item';
import { LocaleContext } from '@cld/intl';
import { MobileMenu, MobileMenuItem } from '@cld/mobile-menu';
import { useSupportedLanguages } from './useSupportedLanguages';

export const MobileLanguageSelector = () => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const { languages, setLocale } = useSupportedLanguages();
  const locale = useContext(LocaleContext);
  const formattedLocale = locale.split('-')[0].toUpperCase();

  return (
    <>
      {displayMenu && (
        <MobileMenu backdropClickHandler={() => setDisplayMenu(false)}>
          {languages.map(({ languageKey, languageLabel }) => (
            <MobileMenuItem
              isSelected={languageKey === locale}
              key={languageKey}
              onClick={() => {
                setLocale(languageKey);
                setDisplayMenu(false);
              }}
            >
              <ItemLayout>{languageLabel}</ItemLayout>
            </MobileMenuItem>
          ))}
        </MobileMenu>
      )}
      <Button size="sm" tone="plain" variant="secondary" rightSlot={<Language />} onClick={() => setDisplayMenu(true)} />
    </>
  );
};
