import { Icons } from '@cld/icon';

const ASSET_TYPE_ICONS: { [K in string]: Icons } = {
  audio: 'audio',
  image: 'photo',
  video: 'video',
  default: 'file',
};

export default (type: string) => ASSET_TYPE_ICONS[type] || ASSET_TYPE_ICONS.default;
