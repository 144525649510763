import { createTheme, globalTheme } from '@clds/component-theme';

export const theme = createTheme({
  background: {
    color: {
      base: globalTheme.palette.background,
    },
  },
  font: {
    color: {
      base: globalTheme.palette.contrastLow,
    },
  },
});
