import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cloudinaryLogo: {
    id: 'cloudinaryLogo',
    defaultMessage: 'Cloudinary logo',
  },
  poweredBy: {
    id: 'poweredBy',
    defaultMessage: 'Powered by',
  },
});
