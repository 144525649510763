import { Asset, Collection, ImageAsset, VideoAsset } from '@cld/console-apps-types';

export const getAssetTrackingParams = ({ asset, collection }: { asset: Asset & { position: number }; collection: Collection & { position: number } }) => ({
  collection_name: collection.name,
  collection_position: collection.position,
  asset_name: asset.displayName || asset.filename,
  asset_position: asset.position,
  asset_resource: asset.type,
  asset_type: asset.format.toUpperCase(),
  asset_size: asset.bytes,
  asset_width: (asset as ImageAsset)?.width ?? 0,
  asset_height: (asset as ImageAsset)?.height ?? 0,
  asset_duration: (asset as VideoAsset)?.duration ?? 0,
});
