import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Typography } from '@clds/typography';
import { CheckCircle } from '@clds/icon';
import { Item } from '@clds/item';
import { Paper } from '@clds/paper';
import { ModalCard, ModalContent, ModalHeader } from '@clds/modal';

export const StyledModalWrapper = styled(ModalCard)`
  max-width: 352px;
  max-height: 448px;
  background-color: ${globalTheme.palette.background};
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: ${globalTheme.palette.surfaceAlt};
  border-bottom: 1px solid ${globalTheme.palette.secondary};
`;

export const StyledModalHeaderItems = styled.span`
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.sm};
`;

export const StyledModalContent = styled(ModalContent)`
  background-color: ${globalTheme.palette.surfaceAlt};
  padding: ${globalTheme.spacing.lg} ${globalTheme.spacing.lg} 0px ${globalTheme.spacing.lg};
  min-height: 280px;

  ${Typography} {
    display: block;
    margin-bottom: ${globalTheme.spacing.lg};
    margin-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledCheckCircle = styled(CheckCircle)`
  margin-right: ${globalTheme.spacing.xs};
`;

export const StyledPaper = styled(Paper)`
  max-height: 232px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${globalTheme.palette.secondary};
  }
`;

export const StyledItem = styled(Item)`
  margin-bottom: ${globalTheme.spacing.lg};
`;
