import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { usePhone } from '@clds/style-utils';
import { AssetMetadataVertical } from '@cld/asset-metadata';
import Button, { ButtonMenu } from '@cld/button';
import { Icon } from '@cld/icon';
import { messages } from '../../i18n';
import { getAssetTrackingParams } from '../../services/tracking/tracking';
import { useCloudinaryTracker } from '../../common/hooks/useCloudinaryTracker';

const Root = styled.header`
  display: flex;
  align-items: center;
  padding: ${globalTheme.spacing.md};
  background-color: ${globalTheme.palette.surface};
  justify-content: space-between;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin-left: ${globalTheme.spacing.md};

  ${Button} {
    margin-right: ${globalTheme.spacing.xs};
  }
`;

export const Header = ({ asset, onClose, collection, onDownload }) => {
  const isPhone = usePhone();
  const cloudinaryTracker = useCloudinaryTracker();

  const trackAssetEvent = useCallback(
    (extraParams) =>
      cloudinaryTracker({
        ...getAssetTrackingParams({ asset, collection }),
        ...extraParams,
      }),
    [asset, cloudinaryTracker, collection]
  );

  return (
    <Root data-test="header">
      <AssetMetadataVertical
        type={asset.type}
        filename={asset.displayName || asset.filename}
        bytes={asset.bytes}
        height={asset.height}
        width={asset.width}
        duration={asset.duration}
        color="contrastLow"
      />

      {asset.urls.originalDownload && (
        <Actions>
          {onDownload && (
            <Button variant="secondary" onClick={onDownload} data-test="download-button">
              <Icon name="fileDownload" />
              {!isPhone && <FormattedMessage {...messages.assetDownload} />}
            </Button>
          )}

          <ButtonMenu
            onClick={() => {
              onClose();
              trackAssetEvent({ eventName: 'portal_asset_preview_close_click' });
            }}
            data-test="close-button"
          >
            <Icon name="cross" />
          </ButtonMenu>
        </Actions>
      )}
    </Root>
  );
};

export const HeaderHeight = {
  desktop: 76,
  phone: 70,
};

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
};
