import { getPathname } from './getPathname';

/**
 * Retrieves app properties from two location pathname segments.
 * resourceId is an optional segment.
 */
export const getPropertiesFromPathname = () => {
  const [firstSegment, secondSegment] = getPathname().substring(1).split('/');
  return { customerId: firstSegment, ...(secondSegment && { resourceId: secondSegment }) };
};
