// istanbul ignore file

import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { TextSkeleton, type TextSkeletonProps } from '@cld/skeleton';

const StyledTextSkeleton = styled(TextSkeleton)<TextSkeletonProps & { width: string }>`
  height: 12px;
  width: ${(props) => props.width};
  margin-bottom: ${globalTheme.spacing.lg};
`;

const Wrapper = styled.div`
  padding: ${globalTheme.spacing.md};
`;

export const NavigationSkeleton = () => (
  <Wrapper>
    <StyledTextSkeleton isLoading width="50%" />
    <StyledTextSkeleton isLoading width="50%" />
    <StyledTextSkeleton isLoading width="60%" />
    <StyledTextSkeleton isLoading width="50%" />
    <StyledTextSkeleton isLoading width="40%" />
    <StyledTextSkeleton isLoading width="50%" />
    <StyledTextSkeleton isLoading width="40%" />
  </Wrapper>
);
