import { createTheme, globalTheme } from '@clds/component-theme';

const { color, palette } = globalTheme;

export const t = createTheme({
  caption: {
    color: palette.contrastHigh,
  },
  icon: {
    size: 50,
    color: {
      raw: color.gray60,
      video: color.white,
      shadow: color.black,
    },
  },
  background: {
    color: {
      default: color.gray50,
      audio: color.gray40,
      error: '#fcc8c4',
    },
  },
});
