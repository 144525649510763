import * as yup from '@cld/yup-extra';

import { AssetType } from '../AssetType';
import { NonSocialUploadType } from '../UploadType';
import { assetBaseSchema } from './AssetBase';

export const audioAssetSchema = assetBaseSchema
  .shape({
    type: yup.mixed().oneOf([AssetType.Audio]).required(),
    uploadType: yup.mixed().oneOf(Object.values(NonSocialUploadType)).required(),
    duration: yup.number().nullable(),
  })
  .required();

export type AudioAsset = yup.InferType<typeof audioAssetSchema>;
