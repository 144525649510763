import { normalize } from 'polished';
import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { createGlobalStyle, useTheme } from 'styled-components';
import { globalTheme, ThemeWithOverrides } from '@clds/component-theme';
import { useDocument, getLinkElement } from './helpers';

const GlobalStyle = createGlobalStyle`
  ${normalize()};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a,
  a:visited {
    text-decoration: none;
        color: ${globalTheme.palette.primary};
  }

  body {
    font-weight: normal;
    font-size: ${globalTheme.globals.font.size};
    font-family: ${globalTheme.globals.font.family};
    background-color: ${globalTheme.palette.background};
    color: ${globalTheme.color.gray70};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
  }
`;

const InjectFont: FC<React.PropsWithChildren<{ children: ReactElement }>> = ({ children }) => {
  const theme = useTheme();
  const document = useDocument();
  const fontElement = useMemo(() => (document ? getLinkElement(document) : undefined), [document]);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
  const fontUrl = (theme as any).globals.font.url as string;

  useEffect(() => {
    if (fontElement && document) {
      document.head.appendChild(fontElement);
    }

    return () => {
      if (fontElement && document) {
        document.head.removeChild(fontElement);
      }
    };
  }, [document, fontElement]);
  useEffect(() => {
    if (fontElement) {
      fontElement.href = fontUrl;
    }
  }, [fontUrl, fontElement]);

  return children;
};

const GlobalStyleDefaultExport: FC<React.PropsWithChildren<{ theme?: ThemeWithOverrides }>> = (props) => (
  <InjectFont>
    <GlobalStyle {...props} />
  </InjectFont>
);

export default GlobalStyleDefaultExport;
