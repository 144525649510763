import { FlattenSimpleInterpolation } from 'styled-components';
import { DrawerThemeProps } from './types';

const isTopOrBottom = (position: DrawerThemeProps['position']) => position && ['top', 'bottom'].includes(position);

export const setPosition = (position: DrawerThemeProps['position']) =>
  position
    ? `
${position}: 0;
${isTopOrBottom(position) ? 'height: auto;' : ''}
`
    : '';

export const getWidth = (position: DrawerThemeProps['position'], size: string | FlattenSimpleInterpolation) => (isTopOrBottom(position) ? '100%' : size);
