/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import parse from 'html-react-parser';
import { Asset, Collection, Description, normalizeAssetType, normalizeAssetFormat, validateAsset } from '@cld/console-apps-types';
import { htmlSerializer, WysiwygEditorNode } from '@cld/wysiwyg-editor';
import { PortalCollections } from './PortalData';
import { PortalCollectionsDto, portalCollectionsSchema } from './PortalCollections.dto';
import { PortalInfoDto, portalInfoSchema } from './PortalInfo.dto';

const normalizeDescription = (rawDescription: PortalInfoDto['description']) => {
  if (!rawDescription) {
    return undefined;
  }

  const serializedDescription = htmlSerializer(rawDescription as WysiwygEditorNode[]);
  // Parse converts string elements, e.g: '<div>blabla</div>' into React.Element,
  // which helps us to prevent the usage of dangerouslySetInnerHTML;

  const descriptionElements: Description = serializedDescription.map((element: string) => parse(element)).flat();
  return descriptionElements;
};

export const parseCollections = (rawData: unknown): PortalCollections => {
  const dto: PortalCollectionsDto = portalCollectionsSchema.validateSync(rawData);

  const collections: Array<Collection> = dto.collections.map((collectionDto) => {
    const assets: Array<Asset> = collectionDto.resources.assets.map((assetDto) => {
      const type = normalizeAssetType(assetDto.resourceType, assetDto.resourceSubtype);
      const format = normalizeAssetFormat(assetDto.resourceType, assetDto.publicId, assetDto.format);
      return validateAsset(assetDto.uploadType, type, { ...assetDto, format, type });
    });

    return {
      id: collectionDto.id,
      description: normalizeDescription(collectionDto.description),
      name: collectionDto.name,
      assets,
    };
  });

  return collections;
};

export const parseInfo = (rawData: unknown) => {
  const dto: PortalInfoDto = portalInfoSchema.validateSync(rawData);
  const normalizedDescription = normalizeDescription(dto.description);

  return { ...dto, description: normalizedDescription };
};
