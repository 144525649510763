import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { useDesktop, media } from '@clds/style-utils';
import { LanguageProvider } from '@cld/intl';
import { loadMessages, messages } from './i18n';
import { theme } from './theme';

const Text = styled.div`
  margin-right: ${globalTheme.spacing.xs};
`;
const LeftContent = styled(Text)`
  flex: 1;
`;
const StyledImage = styled.img`
  height: 27px;
`;

const LogoLink = () => {
  const { formatMessage } = useIntl();
  return (
    <a href="https://cloudinary.com/">
      <StyledImage
        src="https://res-s.cloudinary.com/prod/image/upload/v1598426791/console/cloudinary_logo_blue_0720.svg"
        alt={formatMessage(messages.cloudinaryLogo)}
      />
    </a>
  );
};

const Root = styled.div`
  background: ${theme.background.color.base};
  color: ${theme.font.color.base};
  padding: ${globalTheme.spacing.sm};
  font-size: ${globalTheme.font.size.xxs};
  flex-shrink: 0; // Fix iPhone Safari bug

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.desktop({ minOnly: true })} {
    padding: ${globalTheme.spacing.xs};
    flex-direction: row;
  }
`;

export interface FooterProps {
  children?: React.ReactChild;
}

export const Footer: FC<React.PropsWithChildren<FooterProps>> = ({ children }) => {
  const isDesktop = useDesktop({ minOnly: true });

  return (
    <LanguageProvider loadMessages={loadMessages}>
      <Root>
        <LeftContent>{children}</LeftContent>
        {isDesktop && (
          <Text>
            <FormattedMessage {...messages.poweredBy} />
          </Text>
        )}
        <LogoLink />
      </Root>
    </LanguageProvider>
  );
};

export default Footer;
