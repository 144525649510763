import { useContext, useEffect } from 'react';
import { LocaleContext } from './locale-context';

export const useUpdatedDocumentLang = () => {
  const locale = useContext(LocaleContext);

  useEffect(() => {
    document.documentElement.setAttribute('lang', locale);
  }, [locale]);
};
