import { Element } from 'react-scroll';
import { InView } from 'react-intersection-observer';
import React, { useState, useCallback } from 'react';
import { Preview } from './Preview/Preview';
import { COLLECTIONS_LIST_ID } from './App.constants';
import { CollectionGrid } from './AssetGrid/CollectionGrid';
import { getAssetTrackingParams } from '../services/tracking/tracking';
import { useCloudinaryTracker } from '../common/hooks/useCloudinaryTracker';
import { usePreviewNavigation } from '../common/hooks/usePreviewNavigation';
import { isAssetDownloadSupported, isCollectionDownloadSupported } from './App.helpers';
import { StyledCollectionsListWrapper, StyledDownloadPresetsModal } from './App.styles';

const initialAssetsToBeDownloadedInfo = { assets: [], collectionId: null, isCollectionDownload: false };

export const CollectionsAccordion = ({
  contentRef,
  allFetchedCollections,
  isScrollingFromSideMenu,
  downloadPresets,
  preventDownloadOriginal,
  publicLinkId,
  setActiveCollectionId,
  renderProgress,
}) => {
  const cloudinaryTracker = useCloudinaryTracker();
  const [assetsToBeDownloadedInfo, setAssetsToBeDownloadedInfo] = useState(initialAssetsToBeDownloadedInfo);
  const { canNavigateToNext, canNavigateToPrevious, handleClose, handleNext, handlePrevious, previewedAsset, setPreview, previewedCollection } =
    usePreviewNavigation(allFetchedCollections);

  const trackAssetEvent = useCallback(
    ({ asset, collection, ...extraParams }) =>
      cloudinaryTracker({
        ...getAssetTrackingParams({ asset, collection }),
        ...extraParams,
      }),
    [cloudinaryTracker]
  );

  const handleDownloadAssetAttempt = useCallback(({ asset, collectionId }) => {
    // TODO: remove asset.urls.originalDownload
    setAssetsToBeDownloadedInfo({ assets: [asset], collectionId });
  }, []);

  const handleDownloadCollectionAttempt = useCallback(({ collection }) => {
    setAssetsToBeDownloadedInfo({ assets: collection.assets, collectionId: collection.id, isCollectionDownload: true });
  }, []);

  const onAssetDownload = useCallback(
    (asset, collection) => {
      handleDownloadAssetAttempt({ asset, collectionId: collection.id });

      trackAssetEvent({
        eventName: 'portal_asset_menu_download_click',
        asset,
        collection,
      });
    },
    [handleDownloadAssetAttempt, trackAssetEvent]
  );

  const getOnAssetDownload = useCallback(
    (asset) => {
      const isDownloadSupported = isAssetDownloadSupported({ asset, downloadPresets, preventDownloadOriginal }) ? onAssetDownload : undefined;
      return isDownloadSupported;
    },
    [downloadPresets, onAssetDownload, preventDownloadOriginal]
  );

  const onCollectionDownload = useCallback(
    (event, collection) => {
      // do not collapse collection
      event.stopPropagation();
      handleDownloadCollectionAttempt({ collection });
      cloudinaryTracker({ eventName: 'portal_collection_download_click', collection_name: collection.name, collection_position: collection.position });
    },
    [cloudinaryTracker, handleDownloadCollectionAttempt]
  );

  const getOnCollectionDownload = useCallback(
    (collection) => {
      return isCollectionDownloadSupported({ collection, downloadPresets, preventDownloadOriginal })
        ? (event) => onCollectionDownload(event, collection)
        : undefined;
    },
    [downloadPresets, onCollectionDownload, preventDownloadOriginal]
  );

  const onDownloadPreviewedAsset = useCallback(() => {
    handleDownloadAssetAttempt({ asset: previewedAsset, collectionId: previewedCollection.id });
    trackAssetEvent({
      eventName: 'portal_asset_preview_download_click',
      asset: previewedAsset,
      collection: previewedCollection,
    });
  }, [handleDownloadAssetAttempt, previewedAsset, previewedCollection, trackAssetEvent]);

  const renderDownloadsPresetsModal = () => {
    const { assets, collectionId, isCollectionDownload } = assetsToBeDownloadedInfo;
    return (
      assets.length > 0 && (
        // TODO DownloadPresetsModal crashes if it is open and assets is undefined however it should handle that since that makes it easier to consume it
        // TDOO DownloadPresetsModal assets should unify the structure and accept only an array and not sometimes a single asset and sometimes an array
        <StyledDownloadPresetsModal
          presets={downloadPresets}
          onClose={() => setAssetsToBeDownloadedInfo(initialAssetsToBeDownloadedInfo)}
          preventDownload={preventDownloadOriginal}
          assets={isCollectionDownload ? assets : assets[0]}
          publicLinkId={publicLinkId}
          isOpen={assets.length > 0}
          collectionId={collectionId}
        />
      )
    );
  };

  const handleCollectionIntersectionChange = (collectionId) => (inView) => {
    if (inView && !isScrollingFromSideMenu) {
      setActiveCollectionId(() => collectionId);
    }
  };

  const renderPreview = () => {
    if (!previewedAsset) {
      return null;
    }

    const isDownloadSupported = isAssetDownloadSupported({ asset: previewedAsset, preventDownloadOriginal, downloadPresets });
    const onDownload = isDownloadSupported ? onDownloadPreviewedAsset : undefined;
    return (
      <Preview
        canNavigateToNext={canNavigateToNext}
        canNavigateToPrevious={canNavigateToPrevious}
        onClose={handleClose}
        onNext={handleNext}
        onPrevious={handlePrevious}
        previewedAsset={previewedAsset}
        previewedCollection={previewedCollection}
        onDownload={onDownload}
      />
    );
  };

  return (
    <>
      <StyledCollectionsListWrapper id={COLLECTIONS_LIST_ID}>
        {allFetchedCollections.map((collection, collectionIndex) => {
          const handleSetPreviewedAssetIndex = (newPreviewedAssetIndex) => {
            setPreview(collectionIndex, newPreviewedAssetIndex);
          };
          const shouldAllowDownloadingCollection = downloadPresets.length > 0 || !preventDownloadOriginal;

          return (
            <Element key={collection.id} name={collection.id}>
              <InView
                as="div"
                id={collection.id}
                root={contentRef.current}
                rootMargin="0% 0% -100% 0%"
                onChange={handleCollectionIntersectionChange(collection.id)}
              >
                <CollectionGrid
                  collection={collection}
                  setPreviewedAssetIndex={handleSetPreviewedAssetIndex}
                  getOnAssetDownload={getOnAssetDownload}
                  onCollectionDownload={shouldAllowDownloadingCollection && getOnCollectionDownload(collection)}
                />
              </InView>
            </Element>
          );
        })}
        {renderProgress()}
      </StyledCollectionsListWrapper>
      {renderDownloadsPresetsModal()}
      {renderPreview()}
    </>
  );
};
