import React, { createContext, FC, useContext, useState, useMemo, useCallback } from 'react';

export type CollectionsToggleState = Record<string, boolean>;

export interface CollectionState {
  collectionsState: CollectionsToggleState;
  setOpen: (id: string, open: boolean) => void;
}

const CollectionStateContext = createContext<CollectionState | undefined>(undefined);

export const CollectionStateProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [collectionsState, setCollectionsState] = useState<CollectionsToggleState>({});

  const setOpen = useCallback(
    (id: string, open: boolean) =>
      setCollectionsState({
        ...collectionsState,
        [id]: open,
      }),
    [collectionsState]
  );

  const value = useMemo(() => ({ collectionsState, setOpen }), [collectionsState, setOpen]);

  return <CollectionStateContext.Provider value={value}>{children}</CollectionStateContext.Provider>;
};

export const useCollectionsStateContext = () => {
  const value = useContext(CollectionStateContext);

  if (!value) {
    throw new Error('You need to wrap your component with CollectionStateProvider');
  }

  return value;
};
