// istanbul ignore file
/* eslint-disable react/no-deprecated */
import React from 'react';
import ReactDOM from 'react-dom';
import envInfoLabel from '@cld/env-info-label';

import App from './components/App';

envInfoLabel();

ReactDOM.render(<App />, document.getElementById('standalone'));
