import { useCallback, useMemo, useState } from 'react';

export const usePreviewNavigation = (collections) => {
  const [{ previewedAssetIndex, previewedCollectionIndex }, setPreviewState] = useState({
    previewedAssetIndex: null,
    previewedCollectionIndex: null,
  });

  const previewedAsset = useMemo(() => {
    const previewedCollection = collections[previewedCollectionIndex];

    if (!previewedCollection) {
      return null;
    }

    const previewedAsset = previewedCollection.assets[previewedAssetIndex];

    return previewedAsset;
  }, [collections, previewedAssetIndex, previewedCollectionIndex]);

  const handleClose = useCallback(() => {
    setPreviewState({ previewedAssetIndex: null, previewedCollectionIndex: null });
  }, [setPreviewState]);

  const handleNext = useCallback(() => {
    const lastCollectionIndex = collections.length - 1;
    const lastAssetIndex = collections[previewedCollectionIndex].assets.length - 1;

    if (previewedCollectionIndex === lastCollectionIndex && previewedAssetIndex === lastAssetIndex) {
      return;
    }

    if (previewedAssetIndex === lastAssetIndex) {
      setPreviewState({ previewedAssetIndex: 0, previewedCollectionIndex: previewedCollectionIndex + 1 });
    } else {
      setPreviewState({ previewedAssetIndex: previewedAssetIndex + 1, previewedCollectionIndex });
    }
  }, [collections, previewedCollectionIndex, previewedAssetIndex, setPreviewState]);

  const handlePrevious = useCallback(() => {
    if (previewedCollectionIndex === 0 && previewedAssetIndex === 0) {
      return;
    }

    if (previewedAssetIndex === 0) {
      const targetCollectionIndex = previewedCollectionIndex - 1;
      const targetAssetIndex = collections[targetCollectionIndex].assets.length - 1;

      setPreviewState({ previewedAssetIndex: targetAssetIndex, previewedCollectionIndex: targetCollectionIndex });
    } else {
      setPreviewState({ previewedAssetIndex: previewedAssetIndex - 1, previewedCollectionIndex });
    }
  }, [collections, previewedCollectionIndex, previewedAssetIndex, setPreviewState]);

  const setPreview = useCallback(
    (collectionIndex, assetIndex) => {
      setPreviewState({ previewedAssetIndex: assetIndex, previewedCollectionIndex: collectionIndex });
    },
    [setPreviewState]
  );

  let canNavigateToNext = false;
  let canNavigateToPrevious = false;

  if (collections && previewedCollectionIndex !== null && previewedAssetIndex !== null) {
    const lastCollectionIndex = collections.length - 1;
    const lastAssetIndex = collections[previewedCollectionIndex].assets.length - 1;

    canNavigateToNext = previewedAssetIndex !== lastAssetIndex || previewedCollectionIndex !== lastCollectionIndex;
    canNavigateToPrevious = previewedCollectionIndex !== 0 || previewedAssetIndex !== 0;
  }

  return {
    canNavigateToNext,
    canNavigateToPrevious,
    handleClose,
    handleNext,
    handlePrevious,
    previewedAsset,
    setPreview,
    previewedCollection: collections[previewedCollectionIndex],
  };
};
