/* istanbul ignore file */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useState, useEffect } from 'react';
import { filter, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@clds/typography';
import { FileDownload } from '@clds/icon';
import { Tooltip } from '@clds/tooltip';
import { ModalCloseIcon, ModalNext } from '@clds/modal';
import { downloadFile } from '@cld/file-download';
import { Asset, isRawAsset } from '@cld/console-apps-types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SharedPreset } from '@cld/collection/src/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getBuildDownloadUrl } from '@cld/collection/src/api/download';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getFilteredPresets, getPresetAssets, hasRawAssets, getAssetDisplayName } from '@cld/collection/src/components/DowloadPresetsModal/utils';
import { messages } from '../i18n';
import { StyledModalWrapper, StyledModalHeader, StyledModalHeaderItems, StyledModalContent } from './DownloadPresetsModalStyled';
import { OriginalDownloadSection } from './OriginalDownloadSection';
import { PresetsSection } from './PresetsSection';

export interface DownloadPresetsModalProps {
  isOpen: boolean;
  onClose: () => void;
  preventDownload: boolean;
  assets: Asset | Asset[];
  presets: SharedPreset[];
  publicLinkId: string;
  collectionId?: string;
  className?: string;
}

export const DownloadPresetsModal: FC<React.PropsWithChildren<DownloadPresetsModalProps>> = ({
  preventDownload,
  assets,
  publicLinkId,
  presets,
  isOpen,
  onClose,
  collectionId,
  className,
}) => {
  const [activeDownloadItems, setActiveDownloadItems] = useState<string[] | never[]>([]);

  useEffect(() => {
    if (!isOpen) {
      setActiveDownloadItems([]);
    }
  }, [isOpen, setActiveDownloadItems]);

  const onDownload = async (externalId?: string, presetName?: string) => {
    setActiveDownloadItems((activeDownloadItems) => [...activeDownloadItems, presetName]);
    const presetDownloadUrl = await getBuildDownloadUrl(publicLinkId, presetName, externalId, collectionId);

    downloadFile({ src: presetDownloadUrl?.redirect });
  };

  let filteredAssets = assets;
  let displayOriginalDownloadSection;
  let displayPresetsSection;
  let presetsList;

  const isCollection = Array.isArray(assets);

  if (isCollection) {
    filteredAssets = preventDownload ? hasRawAssets(assets) : assets;
    displayOriginalDownloadSection = !preventDownload || !isEmpty(hasRawAssets(assets));
    presetsList = getFilteredPresets(assets, presets);
    displayPresetsSection = getPresetAssets(assets) && !isEmpty(presetsList);
  } else {
    const singleAsset = assets;
    displayOriginalDownloadSection = !preventDownload || isRawAsset(singleAsset);
    presetsList = filter(presets, (prest) => prest.presetTypes?.includes(singleAsset?.type));
    displayPresetsSection = !isRawAsset(singleAsset) && !isEmpty(presetsList);
  }

  const modalHeaderTitle = isCollection ? messages.downloadCollectionPresetsModalHeader : messages.downloadSinglePresetsModalHeader;

  return (
    <ModalNext isOpen={isOpen} onClose={onClose} className={className} closeOnEsc>
      <StyledModalWrapper>
        <StyledModalHeader>
          <StyledModalHeaderItems>
            <FileDownload size="lg" />
            <FormattedMessage {...modalHeaderTitle} />
          </StyledModalHeaderItems>
          <ModalCloseIcon />
        </StyledModalHeader>
        <StyledModalContent>
          {!isCollection && (
            <Tooltip placement="bottomRight" title={getAssetDisplayName(assets)}>
              <Typography size="md" type="highContrast" as="span">
                <FormattedMessage {...messages.assetDownload} /> '{getAssetDisplayName(assets)}':
              </Typography>
            </Tooltip>
          )}
          {displayOriginalDownloadSection && <OriginalDownloadSection assets={filteredAssets} onOriginalDownload={onDownload} />}
          {displayPresetsSection && (
            <PresetsSection activePresets={activeDownloadItems} assets={assets} presets={presetsList} onPresetDownload={onDownload} />
          )}
        </StyledModalContent>
      </StyledModalWrapper>
    </ModalNext>
  );
};
